<mat-nav-list>
    <div class="sideHeder" fxHide.gt-sm>
      <div class="nav-header-item">
        <mat-icon>account_box</mat-icon>
        <span>{{userName}}</span>
        <button mat-button>
          <mat-icon>vpn_key</mat-icon>
          <span>Change Password</span>
        </button>
        <button mat-button (click)="onLogout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Log out</span>
        </button>
      </div>
    </div>
    <a class="nav-item"  mat-menu-item  [routerLink]="link.path" [class.active]="isLinkActive(link.path)"   [queryParams] ="link.query" *ngFor="let link of navList" (click)="onClose()">
      <mat-icon>{{link.icon}}</mat-icon>
      <span class="nav-text">{{ link.name }}</span>
    </a>
  </mat-nav-list>