import { EditPortfolioComponent } from './dashboard/portfolio/edit-portfolio/edit-portfolio.component';
import { TeamImagesComponent } from './dashboard/team/team-images/team-images.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router"

import { NotRespondingComponent } from './othere-pages/not-responding/not-responding.component';
import { EditNewsComponent } from './dashboard/news/edit-news/edit-news.component';
import { AuthGuard } from './auth/auth.guard';
import { PromotionComponent } from './dashboard/promotion/promotion.component';
import { NewsComponent } from './dashboard/news/news.component';
import { PortfolioComponent } from './dashboard/portfolio/portfolio.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { TeamComponent } from "./dashboard/team/team.component";
import { TeamDetailComponent } from './dashboard/team/team-detail/team-detail.component';
import { NotfoundComponent } from './othere-pages/notfound/notfound.component';
import { EventpageComponent } from './dashboard/eventpage/eventpage.component';
import { EventEntitiesComponent } from './dashboard/eventpage/event-entities/event-entities.component';
import { PressComponent } from './dashboard/press/press.component';
import { EditPressComponent } from './dashboard/press/edit-press/edit-press.component';
import { PodcastComponent } from './dashboard/podcast/podcast.component';
import { PodcastEntitiesComponent } from './dashboard/podcast/podcast-entities/podcast-entities.component';

const routes: Routes = [
    {
        path: '', component: DashboardComponent, children: [
            { path: 'team', component: TeamComponent, canActivate:[AuthGuard]},
            { path: 'team/images', component: TeamImagesComponent, canActivate:[AuthGuard] },
            { path: 'team/:id', component: TeamDetailComponent, canActivate:[AuthGuard] },
            { path: 'portfolio', component: PortfolioComponent,canActivate:[AuthGuard]  },
            { path: 'portfolio/:id', component: EditPortfolioComponent,canActivate:[AuthGuard]  },
            { path: 'blog', component: NewsComponent,canActivate:[AuthGuard]  },
            { path: 'blog/:id', component: EditNewsComponent,canActivate:[AuthGuard]  },
            { path: 'press-releases', component: PressComponent,canActivate:[AuthGuard]  },
            { path: 'press-releases/:id', component: EditPressComponent,canActivate:[AuthGuard]  },
            { path: 'events', component: EventpageComponent,canActivate:[AuthGuard]  },
            { path: 'events/:id', component: EventEntitiesComponent,canActivate:[AuthGuard]  },
            { path: 'podcasts', component: PodcastComponent,canActivate:[AuthGuard]  },
            { path: 'podcasts/:id', component: PodcastEntitiesComponent,canActivate:[AuthGuard]  },
            { path: 'promotion', component: PromotionComponent,canActivate:[AuthGuard]  },
            { path: 'not-found', component: NotfoundComponent  },
            { path: '', redirectTo: 'team', pathMatch: 'full',canActivate:[AuthGuard]  }
        ],
        
    },
    { path: 'login', component: LoginComponent },
    { path: 'not-responding', component: NotRespondingComponent },

];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers:[AuthGuard]
})
export class AppRoutingModule { }