<div *ngIf="uiService.isLoading" class="disabled">
  <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
</div>
<section *ngIf="news" class="main-div" fxLayout="column" fxLayoutAlign="center center">

  <form #f="ngForm" (submit)="onSave()" autocomplete="nope">
    <mat-toolbar>
      <div fxFlex fxLayout>
        <h2>Edit News</h2>
      </div>
      <div fxFlex fxLayoutGap="5px" fxLayoutAlign="end">
        <button type="button" mat-icon-button (click)="fileUplaod.click()">
          <mat-icon>upload</mat-icon>
        </button>
        <button type="button" mat-raised-button (click)="onCancel()">Cancel</button>
        <button type="submit" mat-raised-button [disabled]="f.invalid" color="primary">Save</button>
      </div>
      <input style="display: none" type="file" (change)="onUplodingNews($event.target.files)" accept=".html"
        #fileUplaod>

    </mat-toolbar>

    <div fxLayout="row" fxLayout.lt-lg="column" fxFlexFill fxLayoutGap="20px">
      <div fxFlex="100" fxLayout="column" fxLayoutGap="15px">


        <mat-form-field hideRequiredMarker fxLayout="column">
          <input matInput placeholder="Title" name="title" required autocomplete="nope" [(ngModel)]="news.title">
        </mat-form-field>

        <mat-form-field *ngIf="url_show" hideRequiredMarker fxFlex>
          <input matInput placeholder="Url" name="url_id" required [(ngModel)]="news.url_id">
        </mat-form-field>
        <div *ngIf="!url_show" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="15px"
          fxLayoutAlign.gt-sm="start center">
          <p *ngIf="!isActive">{{'https://www.armincoinc.com/blog/'+news.group+'/' +news.url_id}}</p>
          <a [href]="'https://www.armincoinc.com/blog/'+news.group+'/' +news.url_id" target="_blank"
            *ngIf="isActive">{{'https://www.armincoinc.com/blog/'+news.group+'/' +news.url_id}}</a>
          <button *ngIf="!isActive" type="button" mat-flat-button class="btn-small"
            (click)="url_show = true">Edit</button>
        </div>

        <mat-card class="card-textArea-short">
          <mat-card-content>
            <mat-form-field appearance="outline" hideRequiredMarker fxFlex>
              <mat-label>Short Description</mat-label>
              <textarea matInput name="short_dec" placeholder="Write a short description for the news here." required
                [(ngModel)]="news.short_dec"></textarea>
            </mat-form-field>
          </mat-card-content>
        </mat-card>


        <mat-card class="card-textArea">
          <mat-card-content>
            <mat-form-field appearance="outline" hideRequiredMarker fxFlex>
              <mat-label>News Content</mat-label>
              <textarea matInput name="content" placeholder="Write the news content here." required
                [(ngModel)]="news.content"></textarea>
            </mat-form-field>
          </mat-card-content>
        </mat-card>

        <app-image-upload [images]="news.images" (uploading)="onUploding($event)" [errorMessage]="wrongfiles"
          choseMode="false" (imageDelete)="imageDelete($event)"></app-image-upload>

      </div>


      <div fxFlex="300px" fxFlex.lt-lg="100" fxLayout="column" fxLayoutGap="15px">
        <mat-card>
          <mat-card-content>
            <div fxFlex fxLayout="column">
              <mat-form-field hideRequiredMarker>
                <mat-select placeholder="Status" name="status" [(ngModel)]="news.status" required [disabled]="isActive">
                  <mat-option value="Draft">Draft</mat-option>
                  <mat-option value="Active">Active</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field hideRequiredMarker>
                <input matInput [matDatepicker]="picker" placeholder="Date Of News" name="date" required
                  [(ngModel)]="news.date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

              </mat-form-field>
              <mat-form-field hideRequiredMarker>
                <input matInput placeholder="Author Name" name="author_name" required autocomplete="nope"
                  [(ngModel)]="news.author_name">
              </mat-form-field>
            </div>

          </mat-card-content>
        </mat-card>
        <mat-card class="card-textArea-tags">
          <mat-card-content>
            <div fxFlex fxLayout="column">
              <mat-form-field hideRequiredMarker>
                <mat-select placeholder="Group" name="group" [(ngModel)]="news.group" required>
                  <mat-option value="design-build">Design-Build</mat-option>

                  <mat-option value="equipment">Dental & Medical</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" hideRequiredMarker fxFlex>
                <mat-label>Tags</mat-label>
                <textarea matInput name="tags" required [(ngModel)]="news.tags"></textarea>
              </mat-form-field>

            </div>

          </mat-card-content>
        </mat-card>

        <mat-card class="card-image">
          <mat-card-header fxLayout="row">
            <h4 fxFlex>Title Image</h4>
            <button type="button" fxLayout fxLayoutAlign="end center" mat-button color="primary"
              (click)="onChose()">Choose Image</button>
          </mat-card-header>
          <mat-card-content>
            <div fxFlex fxLayout="column" fxLayoutAlign="center center">
              <input style="display: none" type="text" name="title_image" required [(ngModel)]="news.title_image">
              <div class="image-empty" fxLayout="column" fxLayoutAlign="center center">
                <img *ngIf="!news.title_image" src="../../../../assets/images/gallery-512.png" empty>
                <app-image-item *ngIf="news.title_image" [src]="news.title_image"
                  (delete)="news.title_image= ''"></app-image-item>

                <h4 *ngIf="!news.title_image">Chose an image from images</h4>
              </div>
            </div>

          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-content fxFlex fxLayout="column">
            <label>Mode</label>
            <mat-radio-group class="radio-group" name="mode" [(ngModel)]="news.mode">
              <mat-radio-button class="radio-button" value="Image">Image</mat-radio-button>
              <mat-radio-button class="radio-button" value="Slide">Slide</mat-radio-button>
              <mat-radio-button class="radio-button" value="Media">Media</mat-radio-button>
            </mat-radio-group>
            <mat-form-field *ngIf="news.mode == 'Media'" hideRequiredMarker>
              <input matInput placeholder="Media Link" name="media_Link" [(ngModel)]="news.media_Link" required>
            </mat-form-field>
            <iframe *ngIf="news.media_Link && news.mode == 'Media'" width="100%" height="auto"
              [src]="news.media_Link | safeUrl" frameborder="0" allow="autoplay; encrypted-media"></iframe>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</section>