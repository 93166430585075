<h2 mat-dialog-title>Add a New Press Release</h2>
<mat-dialog-content fxLayout="column" fxLayoutGap="25px">
  <mat-form-field>
    <input matInput placeholder="Title"autocomplete="nope"  [(ngModel)]="passedData.title"> 
  </mat-form-field>
  
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button mat-button color="warn" [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" [disabled]="passedData.title == ''">Create</button>
</mat-dialog-actions>