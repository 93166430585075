import { FormsModule } from '@angular/forms';
import { CustomsModule } from './../../customs/customs.module';
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from './../../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TeamComponent } from './team.component';
import { TeamImagesComponent } from './team-images/team-images.component';
import { TeamDetailComponent } from './team-detail/team-detail.component';

@NgModule({
    declarations: [
        TeamComponent,
        TeamImagesComponent,
        TeamDetailComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        RouterModule,
        CustomsModule,
        FormsModule,
        
    ]
})
export class TeamModule { }