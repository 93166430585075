import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotRespondingComponent } from './not-responding/not-responding.component';
import { NotfoundComponent } from './notfound/notfound.component';



@NgModule({
    declarations: [
        NotRespondingComponent,
        NotfoundComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule
    ]
})
export class OtherPageModule{}