import { Component, OnInit, OnDestroy } from '@angular/core';
import { UIService } from '../../../ui.service';
import { EventService } from '../event.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { EventModel } from '../event.model';


@Component({
  selector: 'app-event-entities',
  templateUrl: './event-entities.component.html',
  styleUrls: ['./event-entities.component.css']
})
export class EventEntitiesComponent implements OnInit, OnDestroy {

  editMode = false;
  subscriptions: Subscription[] = [];

  id: string;
  eventModel: EventModel;
  OldEventModel: EventModel;
  url_show = false;
  url_temp: string = "";
  finishdate: Date = null;

  startdate: Date = null;

  status = 'Active';
  start_time: string = null;
  finish_time: string = null;
  regchek: boolean = false;
  constructor(public uiService: UIService, private eventService: EventService, private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id'] !== 'new') {
        this.editMode = true;
        this.id = params['id'];
        this.eventService.fetchEvent(this.id);
      } else {
        this.eventModel = {
          url: '',
          title: '',
          subtitle: '',
          start_day: null,
          finish_day: null,
          start_time: 8,
          finish_time: null,
          about: '',
          address1: '',
          address2: '',
          inactive: false,
          instractor: '',
          instractor_link: '',
          year: null,
          month: null,
          group: null,
          registar_link: null,

        }
      }

    });

    this.subscriptions.push(this.eventService.eventChanged.subscribe(x => {
      this.eventModel = x;
      this.OldEventModel = {...x};
      this.startdate = new Date(x.year,x.month -1,x.start_day);
      this.finishdate = new Date(x.year,x.month -1,x.finish_day);
      this.url_temp = x.url;
      this.start_time=x.start_time < 10 ? `0${x.start_time}:00` :  `${x.start_time}:00`;
      this.finish_time= x.finish_time < 10 ?  `0${x.finish_time}:00`: `${x.finish_time}:00`;
      this.regchek = x.registar_link? true :false;
      this.status = x.inactive ? 'Deleted' : 'Active';
      
    }));

    this.subscriptions.push(this.eventService.urlMade.subscribe(result => {
      this.eventModel.url = result.url;
      this.url_temp = result.url;
    }));
  }
  makeUrl(temp) {
    if (temp) {
      if (this.startdate) {
        var year = this.startdate.getFullYear();
        if (!temp.includes(year.toString())) {
          temp = temp + '-' + year;
        }
      }
   

      this.eventService.getUrl(temp);
     
    }
    if (!this.url_temp){
      this.url_temp = this.eventModel.url;
    }

  }


  cleanUrl() {
    this.eventModel.url = null;
  }
  dateChange() {
    if (this.eventModel.url) {
      var year = this.startdate.getFullYear();
      if (!this.eventModel.url.includes(year.toString())) {
        this.eventModel.url = this.eventModel.url + '-' + year;
      }
    }

  }
  onSave() {
    if (!this.eventModel.url) {
      this.snackBar.open("Make a valid Url", null, { duration: 3000, verticalPosition: 'top', panelClass: 'snakbar-warn' });
    }
    else {
      this.eventModel.year = this.startdate.getFullYear();
      this.eventModel.month = this.startdate.getMonth() + 1;
      this.eventModel.start_day = this.startdate.getDate();
      this.eventModel.finish_day = this.finishdate.getDate();
      this.eventModel.start_time = parseInt(this.start_time.split(':')[0]);
      this.eventModel.finish_time = parseInt(this.finish_time.split(':')[0]);
      this.eventModel.inactive = this.status === 'Active'? false : true;
      if (!this.regchek){
        this.eventModel.registar_link = null;
      }
      if (!this.editMode) {
        this.eventService.addEvent(this.eventModel);
      } else {
        this.eventService.editEvent(this.eventModel,this.id);
      }

    }

  }

  onCancel() {
    this.router.navigate(['/events'], { queryParams: { status: this.OldEventModel.inactive? 'deleted' : 'active' } });
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
