import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from './../../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PromotionComponent } from "./promotion.component";

@NgModule({
    declarations: [
        PromotionComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule
    ]
})
export class PromotionModule { }