<mat-toolbar class="mat-elevation-z3">
  <div>
    <h5>Website Administrator</h5>
  </div>
  <div fxFlex fxLayout fxLayoutAlign="end">
      <button mat-button [matMenuTriggerFor]="menu">{{userName}}
          <mat-icon>account_box</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
     
          <button mat-menu-item>
            <mat-icon>vpn_key</mat-icon>
            <span>Change Password</span>
          </button>
   
          <button mat-menu-item (click)="onLogout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Log out</span>
          </button>
        </mat-menu>
  </div>
</mat-toolbar>