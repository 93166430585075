import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Subject } from 'rxjs';
import { HttpService } from './../../http.service';
import { Injectable } from "@angular/core";
import { HttpErrorResponse } from '@angular/common/http';
import { UIService } from '../../ui.service';
import { Press } from './press.model';

@Injectable()
export class PressService {

    presses: Press[] = [];
    pressesChanged = new Subject<Press[]>();
    pressChanged = new Subject<Press>();
    imagesChanged = new Subject<any>();
    imageDeleted = new Subject<any>();
    press: Press;
    constructor(private httpService: HttpService, private router: Router, private uiService: UIService) { }
    fetchPresses(status) {
        this.uiService.isLoading = true;
        this.httpService.getPresses(status)
            .subscribe(result => {

                this.presses = result;
                this.presses.forEach(x => {
                    if (x.title_image != null && x.title_image != "") {
                        x.title_image = environment.serverName + x.title_image
                    }
                    else {
                        x.title_image = "assets/images/no-preview-wide.jpg";
                    }
                });
                this.uiService.isLoading = false;
                this.pressesChanged.next([...this.presses]);
            },err=>{ this.uiService.handleError(err, true);});
    }
    fetchPress(id) {
        this.uiService.isLoading = true;
        this.httpService.getPress(id)
            .subscribe(result => {
                this.press = result;
                this.uiService.isLoading = false;
                this.pressChanged.next({ ...this.press });
            },err=>{ this.uiService.handleError(err, true);});
    }


    addPress(newData) {
        this.uiService.isLoading = true;
        this.httpService.addPress(newData)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.router.navigate(['press-releases/' + result.id])
            },err=>{ this.uiService.handleError(err);});
    }
    editPress(data) {
        this.uiService.isLoading = true;
        this.httpService.editPress(data)
            .subscribe(() => {
                this.uiService.isLoading = false;
                this.router.navigate(['press-releases'], { queryParams: { status: data.draft? 'inactive' : 'active' } });
            },err=>{ this.uiService.handleError(err);});
    }

    changeActivation(id) {
        this.uiService.isLoading = true;
        this.httpService.changePressoActivation(id)
            .subscribe(() => {
                const index = this.presses.findIndex(x => x.id == id);
                this.presses.splice(index, 1);
                this.uiService.isLoading = false;
                this.pressesChanged.next([...this.presses]);
            },err=>{ this.uiService.handleError(err);});
    }
    uploadPressImage(id, formData: FormData) {
        this.uiService.isLoading = true;
        this.httpService.uploadPressImage(id, formData)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.imagesChanged.next(result);
            },err=>{ this.uiService.handleError(err);});
    }

    deletedPressImage(id, path) {
        this.uiService.isLoading = true;
        this.httpService.deletedPressImage(id, path).subscribe(result => {
            this.uiService.isLoading = false;
            this.imageDeleted.next(path);
        },err=>{ 
            this.uiService.handleError(err);});
    }

}