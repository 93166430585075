<div *ngIf="uiService.isLoading" class="disabled">
  <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
</div>
<section *ngIf="portfolio" class="main-div" fxLayout="column" fxLayoutAlign="center center">

  <form #f="ngForm" (submit)="onSave()" autocomplete="nope">
    <mat-toolbar>
      <div fxFlex fxLayout>
        <h2>Edit Portfolio</h2>
      </div>
      <div fxFlex fxLayoutGap="5px" fxLayoutAlign="end">
        <button type="button" mat-raised-button (click)="onCancel()">Cancel</button>
        <button type="submit" mat-raised-button [disabled]="f.invalid" color="primary">Save</button>
      </div>
    </mat-toolbar>

    <div fxLayout="row" fxLayout.lt-lg="column" fxFlexFill fxLayoutGap="20px">
      <div fxFlex="100" fxLayout="column" fxLayoutGap="15px">

     
        <a *ngIf="portfolio.deleted== false" [href]="'https://www.armincoinc.com/portfolio/'+portfolio.p_id" target="_blank">{{'https://www.armincoinc.com/portfolio/'+portfolio.p_id}}</a>
        <p *ngIf="portfolio.deleted== true">{{'https://www.armincoinc.com/portfolio/'+portfolio.p_id}}</p>
        <mat-card>
          <mat-card-content>
            <div fxLayout="column">
              <mat-form-field hideRequiredMarker>
                <input matInput placeholder="Title" name="Title" autocomplete="nope" [(ngModel)]="portfolio.title"
                  required>
              </mat-form-field>

              <mat-form-field hideRequiredMarker>
                <input matInput placeholder="Client Name" name="client" autocomplete="nope" [(ngModel)]="portfolio.client"
                  required>
              </mat-form-field>
            
            </div>
            
          </mat-card-content>
         
        </mat-card>
        
        <mat-card class="card-textArea">
          <mat-card-content>
            <mat-form-field appearance="outline"  fxFlex>
              <mat-label>Description</mat-label>
              <textarea matInput name="content" placeholder="Write description here." [(ngModel)]="portfolio.description"></textarea>
            </mat-form-field>
          </mat-card-content>
        </mat-card>

        <app-image-upload [images]="portfolio.images" (uploading)="onUploding($event)" [errorMessage]="wrongfiles"
          choseMode="false" (imageDelete)="imageDelete($event)"></app-image-upload>
         
      </div>


      <div fxFlex="300px" fxFlex.lt-lg="100" fxLayout="column" fxLayoutGap="15px">
        <mat-card>
          <mat-card-content>
            <div fxFlex fxLayout="column">
              <mat-form-field hideRequiredMarker>
                <mat-select placeholder="Status" name="deleted" [(ngModel)]="portfolio.deleted" required>
                  <mat-option [value]="true">Inctive</mat-option>
                  <mat-option [value]="false">Active</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field hideRequiredMarker>
                <input matInput placeholder="City" name="city" autocomplete="nope" [(ngModel)]="portfolio.city"
                  required>
              </mat-form-field>

              <mat-form-field hideRequiredMarker>
                <input matInput placeholder="State" name="state" autocomplete="nope" [(ngModel)]="portfolio.state"
                  required>
              </mat-form-field>
              <mat-form-field hideRequiredMarker>
                <input matInput placeholder="Order" type="number" name="order" [(ngModel)]="portfolio.order" required>
              </mat-form-field>
            </div>

          </mat-card-content>
        </mat-card>


        <mat-card class="card-image">
          <mat-card-header fxLayout="row">
            <h4 fxFlex>Thumb Image</h4>
            <button type="button" fxLayout fxLayoutAlign="end center" mat-button color="primary" (click)="onChose()">Choose
              Image</button>
          </mat-card-header>
          <mat-card-content>
            <div fxFlex fxLayout="column" fxLayoutAlign="center center">
              <input style="display: none" type="text" name="small_image" required [(ngModel)]="portfolio.small_image">
              <div class="image-empty" fxLayout="column" fxLayoutAlign="center center">
                <img *ngIf="!portfolio.small_image" src="../../../../assets/images/gallery-512.png" empty>
                <app-image-item *ngIf="portfolio.small_image" [src]="portfolio.small_image" (delete)="portfolio.small_image= ''"></app-image-item>

                <h4 *ngIf="!portfolio.small_image">Chose an image from images</h4>
              </div>
            </div>

          </mat-card-content>
        </mat-card>

      </div>
    </div>
  </form>
</section>