import { QuestionDialogComponent } from "./../../../customs/question-dialog/question-dialog.component";
import { ChoseFileDialogComponent } from "./../../../customs/chose-file-dialog/chose-file-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { News } from "./../news.model";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { environment } from "./../../../../environments/environment";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NewsService } from "../news.service";
import { UIService } from "../../../ui.service";

@Component({
  selector: "app-edit-news",
  templateUrl: "./edit-news.component.html",
  styleUrls: ["./edit-news.component.css"],
})
export class EditNewsComponent implements OnInit, OnDestroy {
  url_show = false;
  hostUrl = environment.serverName;
  wrongfiles: string[] = [];
  newsSubscription: Subscription;
  newsUploadPhotosSubscription: Subscription;
  deletePhotosSubscription: Subscription;
  importNewsSubscription: Subscription;
  uploadedNewsSubscription: Subscription;

  groupId = "";
  id = 0;
  news: News;
  isActive = false;
  constructor(
    private newsService: NewsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public uiService: UIService
  ) {}

  ngOnInit() {
    // subscribe to router event
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params["id"];
      this.newsService.fetchNews(this.id);
    });

    this.newsSubscription = this.newsService.newsChanged.subscribe((result) => {
      this.news = result;
      this.groupId = this.news.group;
      this.isActive = this.news.status == "Active";
      this.importNewsSubscription = this.newsService.importedNews.subscribe(
        (result) => {
          if (result) {
            this.setNewsData(result);
          }
        }
      );
      this.uploadedNewsSubscription = this.newsService.uploadedNews.subscribe(
        (result) => {
          if (result) {
            this.setNewsData(result);
          }
        }
      );
    });

    this.newsUploadPhotosSubscription =
      this.newsService.imagesChanged.subscribe((result) => {
        result.forEach((element) => {
          if (element.error != "") {
            this.wrongfiles.push(element.error);
          } else {
            if (this.news.images == null) this.news.images = [];
            if (!this.news.images.includes(element.name))
              this.news.images.splice(0, 0, element.name);
          }
        });
      });

    this.deletePhotosSubscription = this.newsService.imageDeleted.subscribe(
      (result) => {
        const index: number = this.news.images.indexOf(result);
        if (index !== -1) {
          this.news.images.splice(index, 1);
          if (this.news.title_image == result) this.news.title_image = "";
        }
      }
    );
  }

  private setNewsData(data: News) {
    this.news.content = data.content;
    this.news.tags = data.tags;
    this.news.short_dec = data.short_dec;
    this.news.author_name = "Arminco Inc.";
  }

  onSave() {
    this.wrongfiles = [];
    this.newsService.editNews(this.news);
  }

  onUploding(formData) {
    this.newsService.upleadNewsPhoto(this.id, formData);
  }

  onChose() {
    const data = { images: this.news.images, chosen: "" };
    const dialogRef = this.dialog.open(ChoseFileDialogComponent, {
      width: "800px",
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.news.title_image = data.chosen;
      }
    });
  }

  imageDelete(image) {
    const data = "Are you sure you want to delete this image?";
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.newsService.deletedNewsImage(this.news.id, image);
      }
    });
  }
  onCancel() {
    this.newsService.cleanImport();
    this.router.navigate(["/blog"], { queryParams: { group: this.groupId } });
  }

  onUplodingNews(fileList: FileList) {
    this.newsService.onUplodingNews(fileList);
  }
  ngOnDestroy() {
    this.newsSubscription.unsubscribe();
    this.newsUploadPhotosSubscription.unsubscribe();
    this.deletePhotosSubscription.unsubscribe();
    if (this.importNewsSubscription) this.importNewsSubscription.unsubscribe();
    if (this.uploadedNewsSubscription)
      this.uploadedNewsSubscription.unsubscribe();
  }
}
