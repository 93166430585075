<section fxLayout="column" fxLayoutAlign="center center">
  <div class="logo">
    <img src="assets/images/bug.png">
  </div>
    <mat-card>
    
  
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="center center">
          <h1>SERVER IS NOT RESPONDING</h1>
          <img src="assets/images/Server-Error.png">
        </div>
      </mat-card-content>
  
  
    </mat-card>
  <a href="https://armincoinc.com" class="arminco_link">All Right Reserved &copy;Arminco Inc.</a>
  
  </section>