import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})
export class AddNewsComponent implements OnInit {

  groups = [
    {value: 'design-build', viewValue: 'Design-Build'},

    {value: 'equipment', viewValue: 'Dental & Medical'}
  ];
  constructor(@Inject(MAT_DIALOG_DATA) public passedData:any) { }

  ngOnInit() {
  }

}
