import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TeamService } from '../team.service';
import { MatDialog } from '@angular/material/dialog';
import { UIService } from '../../../ui.service';
import { QuestionDialogComponent } from '../../../customs/question-dialog/question-dialog.component';

@Component({
  selector: 'app-team-images',
  templateUrl: './team-images.component.html',
  styleUrls: ['./team-images.component.css']
})
export class TeamImagesComponent implements OnInit, OnDestroy {

  imagesChangedSubscription: Subscription;
  imagesUploadPhotosSubscription: Subscription;
  
  deletePhotosSubscription: Subscription;
  images: string[] = [];
  wrongfiles: string[] = [];
  constructor(private teamService: TeamService,private router:Router, private dialog: MatDialog, public uiService: UIService) { }

  ngOnInit() {
    this.teamService.ftechTeamImages();
    this.imagesChangedSubscription = this.teamService.imagesChanged.subscribe(result => {

      this.images = result;
    });

    this.imagesUploadPhotosSubscription = this.teamService.imagesUploaded.subscribe(result => {
      result.forEach(element => {
        if (element.error != "") {
          this.wrongfiles.push(element.error);
        
        }
        else {
          if (this.images == null)
            this.images = [];
          if (!this.images.includes(element.name))
          this.images.splice(0,0,element.name);
        }
     
      });
    });

    this.deletePhotosSubscription = this.teamService.imageDeleted.subscribe(result => {
      const index: number = this.images.indexOf(result);
      if (index !== -1) {
        this.images.splice(index, 1);
      }

    });
  }

  onUploding(formData) {
    this.wrongfiles = [];
    this.teamService.upleadTeamPhoto(formData);
  }

  imageDelete(image) {
    const data = 'Are you sure you want to delete this image?'
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.teamService.deleteTeamImage(image);
      }
    });

  }

  onCancel() {
    this.router.navigate(['/team'], { queryParams: { status: "active" } });
  }
  ngOnDestroy() {
    if (this.imagesChangedSubscription)
      this.imagesChangedSubscription.unsubscribe();
  }

  
}
