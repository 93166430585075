


import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from './../../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { PortfolioComponent } from './portfolio.component';
import { AddPortfolioComponent } from './add-portfolio/add-portfolio.component';
import { FormsModule } from "@angular/forms";
import { EditPortfolioComponent } from './edit-portfolio/edit-portfolio.component';
import { CustomsModule } from "../../customs/customs.module";

@NgModule({
    declarations: [
        PortfolioComponent,
        AddPortfolioComponent,
        EditPortfolioComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        RouterModule,
        FormsModule,
        CustomsModule
    ],
    entryComponents:[AddPortfolioComponent]
})
export class PortfolioModule { }