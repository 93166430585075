
import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';


import { NavbarComponent } from './navbar/navbar.component';
import { DashboardComponent } from './dashboard.component';

import { MaterialModule } from '../material.module';
import { SidebarModule } from "./sidebar/sidebar.module";

@NgModule({
    declarations: [
        DashboardComponent,
        NavbarComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        RouterModule,
        SidebarModule
    ]
})
export class DashboardModule { }