<div class="main-div">
  <div fxHide.lt-md>
    <app-navbar [userName]="uiService.userName"></app-navbar>
  </div>
  <mat-sidenav-container class="backg-fixed">
    <mat-sidenav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'" #sidenav>
      <app-sidebar-list  [userName]="uiService.userName" (sidebarColse)="onNavClick(sidenav)"></app-sidebar-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-sidebar-mobile-header (sidebarToggle)="sidenav.toggle()"></app-sidebar-mobile-header>
      <main >
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>