import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { AppComponent } from './app.component';
import { UIService } from './ui.service';
import { AuthService } from './auth/auth.service';
import { HttpService } from './http.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthModule } from './auth/auth.module';
import { OtherPageModule } from './othere-pages/other-page.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { NewsModule } from './dashboard/news/news.module';
import { TeamModule } from './dashboard/team/team.module';
import { PortfolioModule } from './dashboard/portfolio/portfolio.module';
import { PromotionModule } from './dashboard/promotion/promotion.module';
import { CustomsModule } from './customs/customs.module';
import { TeamService } from './dashboard/team/team.service';
import { PortfolioService } from './dashboard/portfolio/portfolio.service';
import { NewsService } from './dashboard/news/news.service';
import { EventPageModule } from './dashboard/eventpage/eventpage.module';
import { EventService } from './dashboard/eventpage/event.service';
import { PressModule } from './dashboard/press/press.module';
import { PressService } from './dashboard/press/press.service';
import { PodcastModule } from './dashboard/podcast/podcast.module';
import { PodcastService } from './dashboard/podcast/podcast.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    AuthModule,
    CustomsModule,
    DashboardModule,
    NewsModule,
    PortfolioModule,
    TeamModule,
    OtherPageModule,
    PromotionModule,
    EventPageModule,PressModule,PodcastModule
    

  ],
  providers: [
    AuthService,
    HttpService,
    TeamService,
    PortfolioService,
    NewsService,
    UIService,
    EventService,
    PressService,
    PodcastService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
