import { HttpService } from 'src/app/http.service';
import { UIService } from 'src/app/ui.service';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PodcastModel } from './podcast.model';

@Injectable()
export class PodcastService {
    podcastList: PodcastModel[] = [];
    podcastListChanged = new Subject<PodcastModel[]>();
    podcastChanged = new Subject<PodcastModel>();
    podcastAdded = new Subject();
    constructor(private httpService: HttpService, private uiService: UIService, private router: Router, private snackBar: MatSnackBar) { }

    fetchPodcastList(status: boolean) {
        this.uiService.isLoading = true;
        this.httpService.getPodcasts(status).subscribe(result => {
            this.uiService.isLoading = false;
            this.podcastList = result;

            this.podcastListChanged.next([...this.podcastList]);
        }, err => { this.uiService.handleError(err, true); });
    }

    fetchPodcast(id: number) {
        this.uiService.isLoading = true;
        this.httpService.getPodcast(id)
            .subscribe(result => {
console.log(result);
                this.uiService.isLoading = false;
                this.podcastChanged.next(result);
            }, err => { this.uiService.handleError(err, true); });
    }

    addPodcast(podcast: PodcastModel) {
        this.uiService.isLoading = true;
        this.httpService.addPodcast(podcast)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.podcastAdded.next();
                this.router.navigate(['/podcasts'], { queryParams: { deleted: false  } });
            }, err => {
              
                    this.uiService.isLoading = false;
                    this.uiService.handleError(err);
            });
    }

    editPodcast(podcast: PodcastModel) {
        this.uiService.isLoading = true;
        this.httpService.editPodcast(podcast)
            .subscribe(result => {

                this.uiService.isLoading = false;
                this.router.navigate(['/podcasts'], { queryParams: { deleted: podcast.deleted } });
            }, err => {
           
                    this.uiService.isLoading = false;
                    this.uiService.handleError(err);
            
            });
    }

    changeStatus(podcast:PodcastModel) {
        this.uiService.isLoading = true;
        this.httpService.changePodcastStatus(podcast.id)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.router.navigate(['/podcasts'], { queryParams: { deleted: !podcast.deleted } });
            }, err => {
           
                    this.uiService.isLoading = false;
                    this.uiService.handleError(err);
            
            });
    }
}