import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { RouterModule } from '@angular/router';
import { CustomsModule } from './../../customs/customs.module';
import { PressComponent } from './press.component';
import { EditPressComponent } from './edit-press/edit-press.component';
import { AddPressComponent } from './add-press/add-press.component';






@NgModule({
    declarations: [
        PressComponent,
        EditPressComponent,
        AddPressComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        FlexLayoutModule,
        RouterModule,
        CustomsModule
    ],
    entryComponents: [AddPressComponent]
})
export class PressModule { }