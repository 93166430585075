import { UIService } from './../../ui.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {
  acceptfiles: File[] = [];


  uploadButtonTitle = "";
  @Input() errorMessage: string[] = [];
  @Input() images: string[] = []

  @Input() title: string = "Images";

  @Input() multipleMode = "true";
  @Input() choseMode = "true";
  @Output() uploading = new EventEmitter<FormData>();
  @Output() imageChosen = new EventEmitter<string>();
  
  @Output() imageDelete = new EventEmitter<string>()

  constructor(public uiService:UIService) { }

  ngOnInit() {

  }
  onUploding(event) {
    this.acceptfiles = [];
    this.errorMessage = [];
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].type == "image/jpeg" || event.target.files[i].type == "image/png" || event.target.files[i].type == "image/gif") {
        if (event.target.files[i].size > 1048576) {
          this.errorMessage.push('"' + event.target.files[i].name + '"' + ' is biger than  1 Megabybtes.');
        }
        else {
          this.acceptfiles.push(event.target.files[i]);
        }
      }
      else {
        this.errorMessage.push('"' + event.target.files[i].name + '"' + ' is corrupt or not a supported image (.jpg, .gif, .png).');
      }
    }

    const formData = new FormData();
    for (let i = 0; i < this.acceptfiles.length; i++) {
      formData.append("files", this.acceptfiles[i]);
    }
    this.uploading.emit(formData);
  }

  onChosen(image) {
    this.imageChosen.emit(image);
  }

  onDelete(image){
    this.imageDelete.emit(image);
  }
 
}
