import { UIService } from './../../ui.service';
import { environment } from './../../../environments/environment';
import { Subject } from 'rxjs';
import { Portfolio } from './portfolio.model';
import { HttpService } from './../../http.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class PortfolioService{


   
    portfolios: Portfolio[] = [];
    portfoliosChanged = new Subject<Portfolio[]>();
    portfolioChanged= new Subject<Portfolio>();
    imageDeleted = new Subject<any>();
    imagesChanged = new Subject<any>();
    constructor(private httpService:  HttpService, private uiService: UIService,private router:Router){}
    fetchPortfolios(status){
        this.uiService.isLoading = true;
        this.httpService.getPortfolios(status)
        .subscribe(result => {
            this.uiService.isLoading = false;
            this.portfolios = result;
            this.portfolios.forEach(x => {
                if (x.small_image != null && x.small_image != "") {
                    x.small_image = environment.serverName + x.small_image
                }
                else {
                    x.small_image = "assets/images/no-preview-wide.jpg";
                }
            });
            this.portfoliosChanged.next([...this.portfolios]);
        },err=>{ this.uiService.handleError(err, true);});
    }

    fetchPortfolio(id: number) {
        this.uiService.isLoading = true;
        this.httpService.getPortfolio(id)
            .subscribe(result => {
              
                this.uiService.isLoading = false;
                this.portfolioChanged.next({ ...result });
            },err=>{ this.uiService.handleError(err, true);});
      }

      addPortfolio(portfolio) {
        this.uiService.isLoading = true;
        this.httpService.addPortfolio(portfolio)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.router.navigate(['portfolio/' + result.id])
            },err=>{ this.uiService.handleError(err);})
      }
      editPortfolio(portfolio) {
        this.uiService.isLoading = true;
        this.httpService.editPortfolio(portfolio)
            .subscribe(() => {
                this.uiService.isLoading = false;
                this.router.navigate(['portfolio'], { queryParams: { status: portfolio.deleted? 'inactive' : 'active' } });
            },err=>{ this.uiService.handleError(err);});
      }

      
    changeActivation(id) {
        this.uiService.isLoading = true;
        this.httpService.changePortfolioActivation(id)
            .subscribe(() => {
                const index = this.portfolios.findIndex(x => x.id == id);
                this.portfolios.splice(index, 1);
                this.uiService.isLoading = false;
                this.portfoliosChanged.next([...this.portfolios]);
            },err=>{ this.uiService.handleError(err);});
    }
      upleadPortfolioPhoto(id, formData: FormData) {
        this.uiService.isLoading = true;
        this.httpService.uploadPortfolioImage(id, formData)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.imagesChanged.next(result);
            },err=>{ this.uiService.handleError(err);});
    }

    deletePortfolioImage(id, path) {
        this.uiService.isLoading = true;
        this.httpService.deletedPortfolioImage(id, path).subscribe(result => {
            this.uiService.isLoading = false;
            this.imageDeleted.next(path);
        },err=>{ 
            this.uiService.handleError(err);});
    }
}