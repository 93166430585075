<section fxLayout="column" class="backg" fxLayoutAlign="center center">
<div class="logo">
  <img src="assets/images/logo_sm.png">
</div>
  <!-- <mat-progress-bar fxLayoutAlign="center center" mode="determinate" value="100"></mat-progress-bar> -->
  <mat-card>
  
    <mat-card-header fxLayout="column">
    
      <mat-card-title > 
        <h2>SIGN IN</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center" #f="ngForm" (ngSubmit)="onLogin(f.value)" autocomplete="nope">
        <mat-form-field>
          <input matInput type="text" placeholder="User Name" name="username" autocomplete="false" ngModel>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="password" placeholder="Password" name="password" ngModel>
        </mat-form-field>
        <button *ngIf="!uiService.isLoading" mat-raised-button color="primary" type="submit">Login</button>
        <mat-spinner [diameter]="35" *ngIf="uiService.isLoading"></mat-spinner>
      
      </form>
    </mat-card-content>


  </mat-card>
<a href="https://armincoinc.com" class="arminco_link">All Right Reserved &copy;Arminco Inc.</a>

</section>