import { Component, OnInit, OnDestroy } from '@angular/core';
import { UIService } from '../../../ui.service';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { PodcastModel } from '../podcast.model';
import { PodcastService } from '../podcast.service';
import { ImportEmbendComponent } from '../import-embend/import-embend.component';



@Component({
  selector: 'app-event-entities',
  templateUrl: './podcast-entities.component.html',
  styleUrls: ['./podcast-entities.component.css']
})
export class PodcastEntitiesComponent implements OnInit, OnDestroy {

  editMode = false;
  subscriptions: Subscription[] = [];

  id: number;
  podcastModel: PodcastModel;
  OldPodcastModel: PodcastModel;





  constructor(public uiService: UIService, private service: PodcastService, private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar, private router: Router, private dialog:MatDialog) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id'] !== 'new') {
        this.editMode = true;
        this.id = +params['id'];
        this.service.fetchPodcast(this.id);
      } else {
        this.podcastModel = {
          id: 0,
        
          deleted :false,
          src:''

        }
      }

    });

    this.subscriptions.push(this.service.podcastChanged.subscribe(x => {
      this.podcastModel = x;
      this.OldPodcastModel = {...x};
     
      
    }));

   
  }
  onExport(){


    const dialogRef = this.dialog.open(ImportEmbendComponent, { width: "800px", panelClass: 'dialog-top' });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      
        this.podcastModel.src = result;
      }
    });
  }

  onSave() {
   
  

      
      if (!this.editMode) {
        this.service.addPodcast(this.podcastModel);
      } else {
        this.service.editPodcast(this.podcastModel);
      }

    

  }

  onCancel() {
    if (this.editMode){
      this.router.navigate(['/podcasts'], { queryParams: { deleted: this.OldPodcastModel.deleted } });

    }else{
      this.router.navigate(['/podcasts'], { queryParams: { deleted: false } });

    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
