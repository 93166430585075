import { UIService } from './../ui.service';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpService } from './../http.service';
import { AuthData } from './auth-data.model';
import { User } from './user.model';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthService {

    private user: User = null;

    requstedRoute: string = "/team?status=active";

    constructor(private router: Router, private httpService: HttpService, private snackBar: MatSnackBar, private uiService: UIService) {

    }

    getDecodedAccessToken(token: string): User {
        try {

            return JSON.parse(jwt_decode<any>(token).user);
        }
        catch (Error) {
            return null;
        }
    }

    login(authData: AuthData) {
        this.uiService.isLoading = true;
        this.httpService.login(authData).subscribe(token => {
            this.uiService.isLoading = false;
            sessionStorage.setItem('access_token', token.accessToken);
            this.user = this.getDecodedAccessToken(token.accessToken);
            this.uiService.userName = (this.user.fName + " " + this.user.lName).trim();
            this.router.navigateByUrl(this.requstedRoute);

        }, (err: HttpErrorResponse) => {

            this.uiService.isLoading = false;
            if (err.statusText && err.statusText == "Unauthorized")
                this.snackBar.open("Invalid username or password.", null, { duration: 3000, verticalPosition: 'top', panelClass: 'snakbar-warn' });
            else {
              this.uiService.handleError(err,true);
            }
        });
    }

    logout() {
        this.user = null;
        sessionStorage.removeItem('access_token');
        this.router.navigate(['/login']);
    }
    isAuth() {
        var access_token = sessionStorage.getItem('access_token');
        this.user = this.getDecodedAccessToken(access_token);
        if (access_token != null && this.user != null) {
            this.uiService.userName = (this.user.fName + " " + this.user.lName).trim();
            return true;
        }
        else {
            return false;
        }

    }

}