import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class UIService {
  isLoading = false;
  userName = "";
  constructor(public snackBar: MatSnackBar, private router: Router) {}
  handleError(err: HttpErrorResponse, loadMetod = false) {
    this.isLoading = false;
    if (err.error && err.error.code == "401c")
      this.snackBar.open(err.error.message, null, {
        duration: 3000,
        verticalPosition: "top",
        panelClass: "snakbar-warn",
      });
    else if ((err.error && err.error.code == "404c") || err.status == 404)
      this.router.navigate(["/not-found"]);
    else if ((!err.status || err.status == 0) && !loadMetod)
      this.snackBar.open("Server is not responding", null, {
        duration: 3000,
        verticalPosition: "top",
        panelClass: "snakbar-warn",
      });
    else if ((!err.status || err.status == 0) && loadMetod)
      this.router.navigate(["/not-responding"]);
    else {
      this.snackBar.open(err.message, null, {
        duration: 3000,
        verticalPosition: "top",
        panelClass: "snakbar-warn",
      });
    }
  }
}
