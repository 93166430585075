import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-import-embend',
  templateUrl: './import-embend.component.html',
  styleUrls: ['./import-embend.component.css']
})
export class ImportEmbendComponent implements OnInit {

  src = "";
  constructor( public dialogRef: MatDialogRef<ImportEmbendComponent>, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }
  onExport() {
    if (this.src.startsWith("<iframe") && this.src.endsWith("></iframe>")) {
      var div = document.createElement('div');
      div.innerHTML = this.src.trim();
      this.dialogRef.close(div.children[0].getAttribute('src'));
    } else {

      this.snackBar.open("Invalid Iframe Embed Code", null, { duration: 3000, verticalPosition: 'top', panelClass: 'snakbar-warn' });

    }

  }
}
