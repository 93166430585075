import { UIService } from './../../ui.service';
import { Router } from '@angular/router';

import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { QuestionDialogComponent } from '../../customs/question-dialog/question-dialog.component';
import { PressService } from './press.service';
import { Press } from './press.model';
import { AddPressComponent } from './add-press/add-press.component';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.css']
})
export class PressComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['select', 'image', 'title', 'date', 'mode'];
  dataSource = new MatTableDataSource<Press>();
  selection = new SelectionModel<Press>(true, []);
  newswsSubscription: Subscription;
  status: string;

  constructor(private pressService: PressService, private router: Router, private activatedRoute: ActivatedRoute, private dialog: MatDialog, public uiService:UIService) { }

  ngOnInit() {
    // subscribe to router event
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.status = params['status'];
      this.pressService.fetchPresses(this.status)
     });
    ;

    this.newswsSubscription = this.pressService.pressesChanged.subscribe(result => {
      this.dataSource.data = result;
      console.log(result);
    });

  }



  addNews() {
    const newNews = { title: ""};

    const dialogRef = this.dialog.open(AddPressComponent, { width: "800px", panelClass: 'dialog-top', data: newNews });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pressService.addPress(newNews);
      }
    });
  }

  onEdit(id) {
    this.router.navigate(['/press-releases/' + id]);
  }
  changeStatus(press: Press) {
    
    const data =this.status == "active"? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.pressService.changeActivation(press.id);
      }
    });
  }
  ngOnDestroy() {
    this.newswsSubscription.unsubscribe();
  }
}
