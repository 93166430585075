<h2 mat-dialog-title>Add a New Portfolio</h2>

<mat-dialog-content >
  <form #f="ngForm" fxLayout="column" fxLayoutGap="25px" autocomplete="nope">
      <mat-form-field  hideRequiredMarker>
          <input matInput placeholder="Title"   name="Title" autocomplete="nope" [(ngModel)]="passedData.title" required> 
        </mat-form-field>

  

    <mat-form-field hideRequiredMarker>
      <input matInput placeholder="Client Name"   name="client" autocomplete="nope" [(ngModel)]="passedData.client" required> 
    </mat-form-field>

    <div fxLayout.gt-sm="row" fxLayout.xs="column"  fxLayoutGap="25px">
        <mat-form-field hideRequiredMarker>
            <input matInput placeholder="City"   name="city" autocomplete="nope" [(ngModel)]="passedData.city" required> 
          </mat-form-field>
      
          <mat-form-field hideRequiredMarker>
            <input matInput placeholder="State"   name="state" autocomplete="nope" [(ngModel)]="passedData.state" required> 
          </mat-form-field>  

          
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button mat-button color="warn" [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" [disabled]="f.invalid">Create</button>
</mat-dialog-actions>