<div *ngIf="uiService.isLoading" class="disabled">
  <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
</div>

<section *ngIf="(editMode && podcastModel) || !editMode" class="main-div" fxLayout="column"
  fxLayoutAlign="center center">

  <form #f="ngForm" (submit)="onSave()" autocomplete="nope">
    <mat-toolbar>
      <div fxFlex fxLayout>
        <h2 *ngIf="!editMode">New Podcast</h2>
        <h2 *ngIf="editMode">Edit Podcast</h2>
      </div>
      <div fxFlex fxLayoutGap="5px" fxLayoutAlign="end">
        <button type="button" mat-raised-button (click)="onCancel()">Cancel</button>
        <button type="submit" mat-raised-button [disabled]="f.invalid" color="primary">Save</button>
      </div>
    </mat-toolbar>

    <div fxLayout="row" fxLayout.lt-lg="column" fxFlexFill fxLayoutGap="20px">
      <div fxFlex="100" fxLayout="column" fxLayoutGap="15px">
        
        <mat-card>
          <mat-card-content>
            <p *ngIf="podcastModel.src">Embed Https</p>
            <mat-form-field appearance="outline" hideRequiredMarker  fxLayout="column" *ngIf="podcastModel.src">
            
              <input matInput name="src" readonly required autocomplete="nope"  [(ngModel)]="podcastModel.src">
            </mat-form-field>
            <button mat-raised-button type="button" color="primary" (click)="onExport()">Import Embed Https</button>
          </mat-card-content>
        </mat-card>
   

        <div class="d-flex flex-column mt-5 mb-4 align-items-center justify-content-center" >
          <p style="margin-bottom: 2px;">Perview</p>
          <iframe [src]='podcastModel.src | safeUrl' frameborder='0' marginheight='0' marginwidth='0' scrolling='no'
            allowfullscreen></iframe>

        </div>




      </div>


      <div fxFlex="300px" fxFlex.lt-lg="100" fxLayout="column" fxLayoutGap="15px">

        <mat-card>
          <mat-card-content>
            <div fxFlex fxLayout="column">
              <mat-form-field hideRequiredMarker>
                <mat-select placeholder="Status" name="deleted" [(ngModel)]="podcastModel.deleted" required>
                  <mat-option [value]='false'>Active</mat-option>
                  <mat-option [value]="true">Deleted</mat-option>
                </mat-select>
              </mat-form-field>


            </div>

          </mat-card-content>
        </mat-card>



      </div>
    </div>

  </form>
</section>