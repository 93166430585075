import { PortfolioService } from './../portfolio.service';
import { Portfolio } from './../portfolio.model';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIService } from '../../../ui.service';
import { ChoseFileDialogComponent } from '../../../customs/chose-file-dialog/chose-file-dialog.component';
import { QuestionDialogComponent } from '../../../customs/question-dialog/question-dialog.component';

@Component({
  selector: 'app-edit-portfolio',
  templateUrl: './edit-portfolio.component.html',
  styleUrls: ['./edit-portfolio.component.css']
})
export class EditPortfolioComponent implements OnInit {
  url_show = false;
  hostUrl = environment.serverName;
  wrongfiles: string[] = [];
  portfolioSubscription: Subscription;
  portfolioUploadPhotosSubscription: Subscription;
  deletePhotosSubscription: Subscription;

  id = 0;
  portfolio: Portfolio;

  constructor(private portfolioService: PortfolioService, private router: Router, private activatedRoute: ActivatedRoute, private dialog: MatDialog, public uiService: UIService) { }

  ngOnInit() {
        // subscribe to router event
        this.activatedRoute.params.subscribe((params: Params) => {
          this.id = params['id'];
          this.portfolioService.fetchPortfolio(this.id);
        });
    
        this.portfolioSubscription = this.portfolioService.portfolioChanged.subscribe(result => {
          this.portfolio = result;
         
        });

        this.portfolioUploadPhotosSubscription = this.portfolioService.imagesChanged.subscribe(result => {
          result.forEach(element => {
            if (element.error != "") {
              this.wrongfiles.push(element.error);
            }
            else {
              if (this.portfolio.images == null)
                this.portfolio.images = [];
              if (!this.portfolio.images.includes(element.name))
                this.portfolio.images.splice(0,0,element.name);
            }
    
          });
        });
      
        this.deletePhotosSubscription = this.portfolioService.imageDeleted.subscribe(result => {
          const index: number = this.portfolio.images.indexOf(result);
          if (index !== -1) {
            this.portfolio.images.splice(index, 1);
          }
    
        });
    
  }

  onSave() {
    this.wrongfiles = [];
    this.portfolioService.editPortfolio(this.portfolio);
  }


  onUploding(formData) {

    this.portfolioService.upleadPortfolioPhoto(this.id, formData);
  }

  onChose() {
    if (this.portfolio.images.length > 0){
      const data = { images: this.portfolio.images, chosen: "" }
      const dialogRef = this.dialog.open(ChoseFileDialogComponent, { width: "800px", data: data });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.portfolio.small_image = data.chosen;
        }
      });
    }

  }

  imageDelete(image) {
    const data = 'Are you sure you want to delete this image?'
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {

        this.portfolioService.deletePortfolioImage(this.portfolio.id, image);
      }
    });
  }
  onCancel() {
    this.router.navigate(['/portfolio'], { queryParams: { status: 'active' } });
  }


  ngOnDestroy() {
    if( this.deletePhotosSubscription)
    this.deletePhotosSubscription.unsubscribe();
    if(this.portfolioSubscription)
    this.portfolioSubscription.unsubscribe();
    if( this.portfolioUploadPhotosSubscription)
    this.portfolioUploadPhotosSubscription.unsubscribe();

  }

}
