import { Component, OnInit, Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'app-sidebar-mobile-header',
  templateUrl: './sidebar-mobile-header.component.html',
  styleUrls: ['./sidebar-mobile-header.component.css']
})
export class SidebarMobileHeaderComponent implements OnInit {
@Output() sidebarToggle = new EventEmitter<void>();
  constructor() { }

  ngOnInit() {
  }

  onToggle(){
    this.sidebarToggle.emit();
  }

  
}
