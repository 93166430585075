
<mat-progress-bar *ngIf="uiService.isLoading" mode="indeterminate" color="warn"></mat-progress-bar>
<mat-card class="card-images" [ngClass]="{'no-border': choseMode == 'true'}">
    <div *ngIf="uiService.isLoading"  class="disabled"></div>
    <mat-card-header fxLayout="row" *ngIf="choseMode == 'false'">
      <h4  fxFlex>{{title}}</h4>
      <button *ngIf="multipleMode=='false'" type="button" fxLayout fxLayoutAlign="end center" mat-button color="primary" (click)="fileUplaod.click()">Upload Image</button>
      <button *ngIf="multipleMode=='true'" type="button" fxLayout fxLayoutAlign="end center" mat-button color="primary" (click)="filesUplaod.click()">Upload Images</button>
    </mat-card-header>
    <mat-card-content>
      
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <mat-card class="warning-list" *ngIf="errorMessage.length > 0">
          <mat-card-header>
            <h3>The following images could not be added:</h3>
          </mat-card-header>
          <mat-card-content>
            <ul>
              <li *ngFor="let item of errorMessage">{{item}}</li>
            </ul>
          </mat-card-content>
          <mat-card-actions>
            <div fxFlex fxLayout="row" fxLayoutAlign="end center">
              <button mat-button color="warn" type="button" (click)="errorMessage = []">
                <mat-icon>close</mat-icon> Close
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
        <input  style="display: none" type="file" (change)="onUploding($event)" multiple accept="image/*" #filesUplaod>
        <input  style="display: none" type="file" (change)="onUploding($event)" accept="image/*" #fileUplaod>
        <div *ngIf="!images || images.length == 0" class="image-empty" fxLayout="column" fxLayoutAlign="center center">
          <img src="../../../../assets/images/gallery-512.png">
          <h3>Upload all images you need to use.</h3>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <app-image-item *ngFor="let image of images" [src]="image" [choseMode]="choseMode" (chosen)="onChosen($event)" (delete)="onDelete($event)"></app-image-item>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
