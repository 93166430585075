import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { RouterModule } from '@angular/router';
import { CustomsModule } from './../../customs/customs.module';

import { AddNewsComponent } from './add-news/add-news.component';
import { EditNewsComponent } from './edit-news/edit-news.component';
import { NewsComponent } from './news.component';




@NgModule({
    declarations: [
        NewsComponent,
        EditNewsComponent,
        AddNewsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        FlexLayoutModule,
        RouterModule,
        CustomsModule
    ],
    entryComponents: [AddNewsComponent]
})
export class NewsModule { }