import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { RouterModule } from '@angular/router';
import { CustomsModule } from './../../customs/customs.module';
import { PodcastComponent } from './podcast.component';
import { PodcastEntitiesComponent } from './podcast-entities/podcast-entities.component';
import { ImportEmbendComponent } from './import-embend/import-embend.component';







@NgModule({
    declarations: [
      PodcastComponent,
      PodcastEntitiesComponent,
      ImportEmbendComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        FlexLayoutModule,
        RouterModule,
        CustomsModule
    ],
    entryComponents: [ImportEmbendComponent]
})
export class PodcastModule { }