import { AuthData } from './auth/auth-data.model';
import { News } from './dashboard/news/news.model';
import { Portfolio } from './dashboard/portfolio/portfolio.model';
import { environment } from '../environments/environment';
import { Team } from './dashboard/team/team.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventModel } from './dashboard/eventpage/event.model';
import { Press } from './dashboard/press/press.model';
import { PodcastModel } from './dashboard/podcast/podcast.model';

@Injectable()
export class HttpService {
    constructor(private httpClient: HttpClient) { }


    // #region Auth
    login(authData: AuthData) {
        return this.httpClient.post<any>(environment.http_url + "siteadmin/" + "login", authData);
    }
    //#endregion Auth



    getPortfolios(status: string) {

        return this.httpClient.get<Portfolio[]>(environment.http_url + "portfolioadmin/" + 'getportfolios?status=' + status)
    }

    getPortfolio(id: number) {

        return this.httpClient.get<Portfolio>(environment.http_url + "portfolioadmin/" + 'getportfolio?id=' + id)
    }

    addPortfolio(portfolio) {
        return this.httpClient.post<Portfolio>(environment.http_url + "portfolioadmin/" + 'addportfolio', portfolio);
    }

    
    editPortfolio(portfolio) {
        return this.httpClient.put(environment.http_url + "portfolioadmin/" + 'editportfolio', portfolio);
    }
    changePortfolioActivation(id) {
        return this.httpClient.put(environment.http_url + "portfolioadmin/" + `changeactivation?id=${id}`, null);
    }
    uploadPortfolioImage(id, formData: FormData) {
        return this.httpClient.post<any>(environment.http_url + "portfolioadmin/" + 'portfoliophotoupload?id=' + id, formData);
    }
    deletedPortfolioImage(id, path) {
        return this.httpClient.get(environment.http_url + "portfolioadmin/" + `deleteportfoliophoto/${id}?path=${path}`);
    }

 


    //#region Team
    getTeams(status: string) {

        return this.httpClient.get<Team[]>(environment.http_url + "teamadmin/" + 'getteams?status=' + status)
    }
    getTeam(id:number) {

        return this.httpClient.get<Team>(environment.http_url + "teamadmin/" + 'getteam?id=' + id)
    }

    getTeamImages() {

        return this.httpClient.get<string[]>(environment.http_url + "teamadmin/getteamimages");
    }

    addTeam(newData) {
        return this.httpClient.post(environment.http_url + "teamadmin/" + 'addteam', newData);
    }

    editTeam(data) {
        return this.httpClient.put(environment.http_url + "teamadmin/" + 'editTeam', data);
    }

    uploadTeamImage(formData: FormData) {
        return this.httpClient.post<any>(environment.http_url + "teamadmin/teamphotoupload", formData);
    }

    deletedTeamImage(path) {
        return this.httpClient.get(environment.http_url + "teamadmin/" + `deleteteamphoto?path=${path}`);
    }
    //#endregion Team

    //#region Blogs

    getNewses(group: string) {

        return this.httpClient.get<News[]>(environment.http_url + "blogadmin/" + 'getblogs?group=' + group)
    }

    getNews(id: number) {

        return this.httpClient.get<News>(environment.http_url + "blogadmin/" + 'getblog?id=' + id)
    }

    addNews(newData) {
        return this.httpClient.post<News>(environment.http_url + "blogadmin/" + 'addblog', newData);
    }

    editNews(data) {
        return this.httpClient.put(environment.http_url + "blogadmin/" + 'editblog', data);
    }
    deletedNews(id) {
        return this.httpClient.put(environment.http_url + "blogadmin/" + `deleteblog?id=${id}`, null);
    }

    uploadNewsImage(id, formData: FormData) {
        return this.httpClient.post<any>(environment.http_url + "blogadmin/" + 'blogphotoupload?id=' + id, formData);
    }
    deletedNewsImage(id, path) {
        return this.httpClient.get(environment.http_url + "blogadmin/" + `deleteblogphoto/${id}?path=${path}`);
    }





    //#endregion Blogs

    


    //#region Presses

    getPresses(status) {

        return this.httpClient.get<Press[]>(environment.http_url + "pressadmin/" + 'getpresses?status=' + status)
    }

    getPress(id: number) {

        return this.httpClient.get<Press>(environment.http_url + "pressadmin/" + 'getpress?id=' + id)
    }

    addPress(newData) {
        return this.httpClient.post<Press>(environment.http_url + "pressadmin/" + 'addpress', newData);
    }

    editPress(data) {
        return this.httpClient.put(environment.http_url + "pressadmin/" + 'editpress', data);
    }
    changePressoActivation(id) {
        return this.httpClient.put(environment.http_url + "pressadmin/" + `changeactivation?id=${id}`, null);
       
    }

    uploadPressImage(id, formData: FormData) {
        return this.httpClient.post<any>(environment.http_url + "pressadmin/" + 'pressphotoupload?id=' + id, formData);
    }
    deletedPressImage(id, path) {
        return this.httpClient.get(environment.http_url + "pressadmin/" + `deletepressphoto/${id}?path=${path}`);
    }





    //#endregion Presses





    //#region Events
    getEvents(status: string) {

        return this.httpClient.get<EventModel[]>(environment.http_url + `eventadmin/getevents?status=${status}`)
    }
    
    getEvent(url: string) {

        return this.httpClient.get<EventModel>(environment.http_url + `eventadmin/getevent/${url}`)
    }
    editEvent(eventModel: EventModel,url: string) {

        return this.httpClient.put(environment.http_url + `eventadmin/editevent?url=${url}`, eventModel)
    }
    getUrl(temp: string) {

        return this.httpClient.get<any>(environment.http_url + `data/makeurl?temp=${temp}`)
    }

    addEvent(event: EventModel){
        return this.httpClient.post(environment.http_url + "eventadmin/" + 'addevent', event);
    }
    //#endregion Events



       //#region Podcasts
       getPodcasts(status: boolean) {

        return this.httpClient.get<PodcastModel[]>(environment.http_url + `podcastadmin/getpodcasts?deleted=${status}`)
    }
    
    getPodcast(id: number) {

        return this.httpClient.get<PodcastModel>(environment.http_url + `podcastadmin/getpodcast?id=${id}`)
    }
    editPodcast(podcastModel:PodcastModel) {

        return this.httpClient.put(environment.http_url + `podcastadmin/edit`, podcastModel)
    }

    changePodcastStatus(id: number) {

        return this.httpClient.put(environment.http_url + `podcastadmin/changestatus?id=${id}`, null)
    }
   
    addPodcast(podcastModel:PodcastModel){
        return this.httpClient.post(environment.http_url + "podcastadmin/add", podcastModel);
    }
    //#endregion Podcasts
}