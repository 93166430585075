import { Subscription } from 'rxjs';
import { TeamService } from './../team.service';
import { NgForm } from '@angular/forms';
import { Team } from './../team.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UIService } from '../../../ui.service';
import { ChoseFileDialogComponent } from '../../../customs/chose-file-dialog/chose-file-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.css']
})
export class TeamDetailComponent implements OnInit, OnDestroy {
  team: Team;
  title: string = "Add New Team";
  imagesChangedSubscription: Subscription;

  teamChangedSubscription: Subscription;
  images: string[] = [];
  id;
  constructor(private teamService: TeamService, private router: Router, public uiService: UIService, private dialog: MatDialog, private activatedRoute: ActivatedRoute, ) { }
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
       this.id = params['id'];
      if (this.id == "new") {
        this.team = { id: 0, title: null, fName: null, lName: null, department: null, image1: null, image2: null, order: null, deleted: false };
      }
      else {
        this.teamService.fetchTeam(this.id);
      }

    });
    this.teamChangedSubscription = this.teamService.teamChanged.subscribe(result => {
      this.team = result;
      this.title = "Edit Team"

    })


  }
  onSave() {
   if (this.id == "new"){
     this.teamService.addTeam(this.team);
   }
   else{
     this.teamService.editTeam(this.team);
   }
  }
  showChoseDialog(imageName) {
    const data = { images: this.images, chosen: "" }
    const dialogRef = this.dialog.open(ChoseFileDialogComponent, { width: "800px", data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (imageName == "image1")
          this.team.image1 = data.chosen;
        else
          this.team.image2 = data.chosen;
      }
      this.imagesChangedSubscription.unsubscribe();
    });
  }
  onChose(imageName) {

    this.teamService.ftechTeamImages();

    this.imagesChangedSubscription = this.teamService.imagesChanged.subscribe(result => {
      this.images = result;
      this.showChoseDialog(imageName);
    });


  }


  onCancel() {
    this.router.navigate(['/team'], { queryParams: { status: "active" } });
  }

  ngOnDestroy() {
    if (this.imagesChangedSubscription)
      this.imagesChangedSubscription.unsubscribe();
      if (this.teamChangedSubscription)
      this.teamChangedSubscription.unsubscribe();
  }


}
