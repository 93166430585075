import { UIService } from "./../../ui.service";
import { Router } from "@angular/router";
import { AddNewsComponent } from "./add-news/add-news.component";
import { ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";
import { SelectionModel } from "@angular/cdk/collections";
import { News } from "./news.model";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { NewsService } from "./news.service";
import { QuestionDialogComponent } from "../../customs/question-dialog/question-dialog.component";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.css"],
})
export class NewsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ["select", "image", "title", "date", "status"];
  dataSource = new MatTableDataSource<News>();
  selection = new SelectionModel<News>(true, []);
  newswsSubscription: Subscription;
  importNewsSubscription: Subscription;

  constructor(
    private newsService: NewsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public uiService: UIService
  ) {}

  ngOnInit() {
    // subscribe to router event
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let group = params["group"];
      this.newsService.fetchNewses(group);
    });

    this.newswsSubscription = this.newsService.newsesChanged.subscribe(
      (result) => {
        this.dataSource.data = result;
      }
    );
    this.importNewsSubscription = this.newsService.uploadedNews.subscribe(
      (reult) => {
        if (reult) {
          this.newsService.importNews(reult);
        }
      }
    );
  }

  addNews() {
    const newNews = { title: "", group: "" };

    const dialogRef = this.dialog.open(AddNewsComponent, {
      width: "800px",
      panelClass: "dialog-top",
      data: newNews,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.newsService.addNews(newNews);
      }
    });
  }

  onEdit(id) {
    this.router.navigate(["/blog/" + id]);
  }
  onDelete(id) {
    const data = "Are you sure you want to delete this news?";
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.newsService.deleteNews(id);
      }
    });
  }

  onUploding(fileList: FileList) {
    this.newsService.onUplodingNews(fileList);
  }

  ngOnDestroy() {
    this.newswsSubscription.unsubscribe();
    if (this.importNewsSubscription) {
      this.importNewsSubscription.unsubscribe();
    }
  }
}
