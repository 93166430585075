import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UIService } from 'src/app/ui.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { EventModel } from './event.model';
import { EventService } from './event.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { QuestionDialogComponent } from 'src/app/customs/question-dialog/question-dialog.component';

@Component({
  selector: 'app-eventpage',
  templateUrl: './eventpage.component.html',
  styleUrls: ['./eventpage.component.css']
})
export class EventpageComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'title', 'date', 'group'];
  dataSource = new MatTableDataSource<EventModel>();
  selection = new SelectionModel<EventModel>(true, []);
  subscriptions: Subscription[] = [];
  status :string;
  constructor( public uiService:UIService,private eventService: EventService, private activatedRoute: ActivatedRoute,private dialog: MatDialog, private router: Router) { }

  ngOnInit() {

      // subscribe to router event
      this.subscriptions.push( this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.status = params['status'];
         this.eventService.fetchEventList(this.status);
       }));
     
       this.subscriptions.push(
         this.eventService.eventListChanged.subscribe(result=>{
        
          this.dataSource.data = result;
          this.dataSource.sort = this.sort
        }));

  }
  addNew(){
    this.router.navigate(['/events/new']);
  }
  onEdit(url){
    this.router.navigate([`/events/${url}`]);
  }

  onSort(){}
  changeStatus(event: EventModel){
    const data = event.inactive? 'Are you sure you want to restore this event?' : 'Are you sure you want to delete this event?'
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        event.inactive =! event.inactive;
        this.eventService.editEvent(event, event.url);
      }
    });
  

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
