import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UIService } from 'src/app/ui.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { QuestionDialogComponent } from 'src/app/customs/question-dialog/question-dialog.component';
import { PodcastService } from './podcast.service';
import { PodcastModel } from './podcast.model';
import { ImportEmbendComponent } from './import-embend/import-embend.component';
import { PodcastModule } from './podcast.module';


@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.css']
})
export class PodcastComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, {static:false}) sort: MatSort;
  displayedColumns: string[] = ['podcast','select'];
  dataSource = new MatTableDataSource<PodcastModel>();
  selection = new SelectionModel<PodcastModel>(true, []);
  subscriptions: Subscription[] = [];
  deleted :boolean = false;
  constructor( public uiService:UIService,private service: PodcastService, private activatedRoute: ActivatedRoute,private dialog: MatDialog, private router: Router) { }

  ngOnInit() {

      // subscribe to router event
      this.subscriptions.push( this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.deleted =  params['deleted'] == 'true'? true : false;
         this.service.fetchPodcastList(this.deleted);
       }));
     
       this.subscriptions.push(
         this.service.podcastListChanged.subscribe(result=>{
        
          this.dataSource.data = result;
          this.dataSource.sort = this.sort
        }),
       this.service.podcastAdded.subscribe(()=> { this.service.fetchPodcastList(this.deleted);}) 
        );

  }
  addNew(){
    const dialogRef = this.dialog.open(ImportEmbendComponent, { width: "800px", panelClass: 'dialog-top' });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      const podcast : PodcastModel = {src:result, deleted:false,id:0};
      this.service.addPodcast(podcast)
      }
    });
  }
  onEdit(id){
    this.router.navigate([`/podcasts/${id}`]);
  }

  onSort(){}
  changeStatus(podcast: PodcastModel){
    const data = podcast.deleted? 'Are you sure you want to restore this podcast?' : 'Are you sure you want to delete this podcast?'
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
       this.service.changeStatus(podcast);
      }
    });
  

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
