import { HttpService } from 'src/app/http.service';
import { UIService } from 'src/app/ui.service';
import { Router } from '@angular/router';
import { EventModel } from './event.model';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class EventService {
    eventList: EventModel[] = [];
    eventListChanged = new Subject<EventModel[]>();
    eventChanged = new Subject<EventModel>();

    urlMade = new Subject<any>();
    constructor(private httpService: HttpService, private uiService: UIService, private router: Router, private snackBar: MatSnackBar) { }

    fetchEventList(status: string) {
        this.uiService.isLoading = true;
        this.httpService.getEvents(status).subscribe(result => {
            this.uiService.isLoading = false;
            this.eventList = result;

            this.eventListChanged.next([...this.eventList]);
        }, err => { this.uiService.handleError(err, true); });
    }
    fetchEvent(url) {
        this.uiService.isLoading = true;
        this.httpService.getEvent(url)
            .subscribe(result => {

                this.uiService.isLoading = false;
                this.eventChanged.next(result);
            }, err => { this.uiService.handleError(err, true); });
    }



    getUrl(temp: string) {
        this.uiService.isLoading = true;
        this.httpService.getUrl(temp).subscribe(result => {
            this.uiService.isLoading = false;
            this.urlMade.next(result);
        }, err => { this.uiService.handleError(err, true); });
    }

    addEvent(event: EventModel) {
        this.uiService.isLoading = true;
        this.httpService.addEvent(event)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.router.navigate(['/events'], { queryParams: { status: 'active' } });
            }, err => {
                if (err.status === 409) {
                    this.uiService.isLoading = false;
                    this.snackBar.open("Duplicate Url. Cannot insert duplicate Url", null, { duration: 3000, verticalPosition: 'top', panelClass: 'snakbar-warn' });
                }
                else {
                    this.uiService.isLoading = false;
                    this.uiService.handleError(err);
                }
            });
    }

    editEvent(eventModel: EventModel, url) {
        this.uiService.isLoading = true;
        this.httpService.editEvent(eventModel, url)
            .subscribe(result => {

                this.uiService.isLoading = false;
                this.router.navigate(['/events'], { queryParams: { status: eventModel.inactive? 'deleted' : 'active' } });
            }, err => {
                if (err.status === 409) {
                    this.uiService.isLoading = false;
                    this.snackBar.open("Duplicate Url. Cannot insert duplicate Url", null, { duration: 3000, verticalPosition: 'top', panelClass: 'snakbar-warn' });
                }
                else {
                    this.uiService.isLoading = false;
                    this.uiService.handleError(err);
                }
            });
    }
}