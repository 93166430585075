import { AuthService } from '../../auth/auth.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Input() userName: string;
  constructor(private authService: AuthService) { }

  ngOnInit() {
    
  }
  onLogout() {
    this.authService.logout();
  }
}
