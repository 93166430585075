import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-chose-file-dialog',
  templateUrl: './chose-file-dialog.component.html',
  styleUrls: ['./chose-file-dialog.component.css']
})
export class ChoseFileDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData:any) { }

  ngOnInit() {
  }

}
