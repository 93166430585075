import { QuestionDialogComponent } from './../../../customs/question-dialog/question-dialog.component';
import { ChoseFileDialogComponent } from './../../../customs/chose-file-dialog/chose-file-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UIService } from '../../../ui.service';
import { PressService } from '../press.service';
import { Press } from '../press.model';


@Component({
  selector: 'app-edit-press',
  templateUrl: './edit-press.component.html',
  styleUrls: ['./edit-press.component.css']
})
export class EditPressComponent implements OnInit, OnDestroy {
  url_show = false;
  hostUrl = environment.serverName;
  wrongfiles: string[] = [];
  newsSubscription: Subscription;
  newsUploadPhotosSubscription: Subscription;
  deletePhotosSubscription: Subscription;
  id = 0;
  press: Press;
  isActive = false;
  constructor(private pressService: PressService, private router: Router, private activatedRoute: ActivatedRoute, private dialog: MatDialog, public uiService: UIService) { }

  ngOnInit() {
    // subscribe to router event
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.pressService.fetchPress(this.id);
    });

    this.newsSubscription = this.pressService.pressChanged.subscribe(result => {
      this.press = result;
      this.isActive = this.press.status == "Active";

    });

    this.newsUploadPhotosSubscription = this.pressService.imagesChanged.subscribe(result => {
      result.forEach(element => {
        if (element.error != "") {
          this.wrongfiles.push(element.error);
        }
        else {
          if (this.press.images == null)
            this.press.images = [];
          if (!this.press.images.includes(element.name))
            this.press.images.splice(0,0,element.name);
        }

      });
    });


    this.deletePhotosSubscription = this.pressService.imageDeleted.subscribe(result => {
      const index: number = this.press.images.indexOf(result);
      if (index !== -1) {
        this.press.images.splice(index, 1);
        if (this.press.title_image == result)
          this.press.title_image = "";
      }

    });



  }

  onSave() {
    this.wrongfiles = [];
    this.pressService.editPress(this.press);
  }


  onUploding(formData) {

    this.pressService.uploadPressImage(this.id, formData);
  }

  onChose() {
    const data = { images: this.press.images, chosen: "" }
    const dialogRef = this.dialog.open(ChoseFileDialogComponent, { width: "800px", data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.press.title_image = data.chosen;
      }
    });
  }

  imageDelete(image) {
    const data = 'Are you sure you want to delete this image?'
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {

        this.pressService.deletedPressImage(this.press.id, image);
      }
    });

  }
  onCancel() {
    this.router.navigate(['/press-releases']);
  }


  ngOnDestroy() {
    this.newsSubscription.unsubscribe();
    this.newsUploadPhotosSubscription.unsubscribe();
    this.deletePhotosSubscription.unsubscribe();

  }
}
