<mat-toolbar>
    <div fxFlex fxLayout>
      <h2>Events</h2>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="end">
      <button mat-raised-button color="primary" (click)="addNew()">
        <mat-icon>add</mat-icon> NEW</button>
    </div>
  </mat-toolbar>

  <mat-card>
    <nav mat-tab-nav-bar>
      <a mat-tab-link routerLink="/events" [queryParams]="{status:'active'}" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
       Active
      </a>
   
      <a mat-tab-link routerLink="/events" [queryParams]="{status:'expired'}" routerLinkActive #rla2="routerLinkActive" [active]="rla2.isActive">
        Expired
      </a>
      <a mat-tab-link routerLink="/events" [queryParams]="{status:'deleted'}" routerLinkActive #rla3="routerLinkActive" [active]="rla3.isActive">
       Deleted
      </a>
    </nav>
    <mat-card-content>
        <div *ngIf="uiService.isLoading"  class="wait">
          <mat-spinner ></mat-spinner>
      </div>
     
      <div class="empty" *ngIf="dataSource.data.length == 0  && !uiService.isLoading" fxLayout="column"  fxLayoutAlign="center center">
          <h2 >No Data</h2>
      </div>
         
          <table  *ngIf="dataSource.data.length > 0 && !uiService.isLoading" mat-table  [dataSource]="dataSource"
             matSort (matSortChange)="onSort()"  >
  
          <!-- Action Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
  
                <button mat-menu-item (click)="onEdit(row.url)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
  
                <button *ngIf="status == 'deleted'" mat-menu-item (click)="changeStatus(row)">
                  <mat-icon>restore_from_trash</mat-icon>
                  <span>Restore</span>
                </button>
  
                
                <button *ngIf="status != 'deleted'" mat-menu-item (click)="changeStatus(row)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <!-- Title Column -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
  
          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element"> {{element.month | monthName}}<span *ngIf="element.start_day"> {{element.start_day}}</span><span
                *ngIf="element.finish_day && element.finish_day != element.start_day">-{{element.finish_day}}</span>, {{element.year}} </td>
          </ng-container>
  
  
          <!-- Group Column -->
          <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef fxHide.lt-md="true"> Group </th>
            <td mat-cell *matCellDef="let element" fxHide.lt-md="true"> {{element.group}} </td>
          </ng-container>
  
       
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns" fxHide.lt-md="true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
</mat-card>