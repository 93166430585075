import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { RouterModule } from '@angular/router';
import { CustomsModule } from './../../customs/customs.module';
import { EventpageComponent } from './eventpage.component';
import { EventEntitiesComponent } from './event-entities/event-entities.component';






@NgModule({
    declarations: [
        EventpageComponent,
        EventEntitiesComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        FlexLayoutModule,
        RouterModule,
        CustomsModule
    ],
    entryComponents: []
})
export class EventPageModule { }