
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";


//This class works befor request

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(){}
    intercept(req: HttpRequest<any>, next: HttpHandler) :Observable<HttpEvent<any>>{
 

        const copiedReq = req.clone({headers: req.headers.append("Authorization", "Bearer " + sessionStorage.getItem("access_token"))});
        return next.handle(copiedReq);
    }

}