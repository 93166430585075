<div *ngIf="uiService.isLoading"  class="disabled">
    <mat-progress-bar  mode="indeterminate" color="warn"></mat-progress-bar>
</div>

<section *ngIf="(editMode && eventModel) || !editMode" class="main-div" fxLayout="column" fxLayoutAlign="center center">
  
    <form #f="ngForm" (submit)="onSave()" autocomplete="nope">
      <mat-toolbar>
        <div fxFlex fxLayout>
          <h2 *ngIf="!editMode">New Event</h2>
          <h2 *ngIf="editMode">Edit Event</h2>
        </div>
        <div fxFlex  fxLayoutGap="5px" fxLayoutAlign="end">
          <button type="button" mat-raised-button  (click)="onCancel()">Cancel</button>
          <button type="submit" mat-raised-button [disabled]="f.invalid" color="primary">Save</button>
        </div>
      </mat-toolbar>
  
      <div fxLayout="row" fxLayout.lt-lg="column" fxFlexFill fxLayoutGap="20px">
        <div fxFlex="100" fxLayout="column" fxLayoutGap="15px">
  
  
          <mat-form-field hideRequiredMarker fxLayout ="column">
            <input matInput placeholder="Title" name="title" required autocomplete="nope" (change)="cleanUrl()"  [(ngModel)]="eventModel.title">
          </mat-form-field>
  
         
  
          <mat-form-field hideRequiredMarker fxLayout ="column">
            <input matInput placeholder="Subtitle" name="subtitle" required autocomplete="nope" [(ngModel)]="eventModel.subtitle">
          </mat-form-field>
          <div *ngIf="url_show" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="15px" fxLayoutAlign.gt-sm="start center">
            <mat-form-field  hideRequiredMarker fxFlex>
              <input matInput placeholder="Url" name="url_temp"  [(ngModel)]="url_temp">
            </mat-form-field>
            <button  type="button" mat-flat-button class="btn-small" color="primary" (click)="makeUrl(url_temp);url_show = false">Done</button>
  
          </div>
          
          <button *ngIf="!editMode && !eventModel.url" type="button" mat-raised-button [disabled]="!eventModel.title" color="warn" (click)="makeUrl(eventModel.title)" >Make a Url</button>

          <div *ngIf="eventModel.url && !url_show" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px" fxLayoutAlign.gt-sm="start center">
            <p *ngIf="eventModel.inactive || !editMode">{{'https://www.armincoinc.com/events/' +eventModel.url}}</p>
            <a  [href]="'https://www.armincoinc.com/events/' + eventModel.url " target="_blank" *ngIf="editMode && !eventModel.inactive">{{'https://www.armincoinc.com/events/'+ eventModel.url}}</a>
            <button type="button" mat-icon-button  color="primary" (click)="url_show = true"> <mat-icon>edit</mat-icon></button>
            <button *ngIf="!editMode" type="button" mat-icon-button  color="warn" (click)="makeUrl(this.eventModel.title)"> <mat-icon>refresh</mat-icon></button>
          </div>
          <mat-card>
           
              <mat-card-content>
                  <h4>Address</h4>
                  <mat-form-field  hideRequiredMarker fxLayout ="column">
                      <input matInput placeholder="Line 1" required name="address1"  [(ngModel)]="eventModel.address1">
                    </mat-form-field>
                    <mat-form-field  hideRequiredMarker fxLayout ="column">
                      <input matInput placeholder="Line 2" name="address2"  [(ngModel)]="eventModel.address2">
                    </mat-form-field>
              </mat-card-content>
           
          </mat-card>
            <mat-card class="card-textArea">
            <mat-card-content>
              <mat-form-field appearance="outline" hideRequiredMarker fxFlex>
                <mat-label>Event Content</mat-label>
                <textarea matInput name="content" placeholder="Write the news content here." required [(ngModel)]="eventModel.about"></textarea>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
       
       
        </div>
  
  
        <div fxFlex="300px" fxFlex.lt-lg="100" fxLayout="column" fxLayoutGap="15px">
   
            <mat-card>
                <mat-card-content>
                  <div fxFlex fxLayout="column">
                    <mat-form-field hideRequiredMarker >
                      <mat-select placeholder="Status" name="status" [(ngModel)]="status" required>
                        <mat-option value="Active">Active</mat-option>
                        <mat-option value="Deleted">Deleted</mat-option>
                      </mat-select>
                    </mat-form-field>
      
                    <mat-form-field hideRequiredMarker >
                        <mat-select placeholder="Group" name="group" [(ngModel)]="eventModel.group" required>
                          <mat-option value="community">Community</mat-option>
                          <mat-option value="ce-class">Ce-class</mat-option>
                          <mat-option value="show">Show</mat-option>
                        </mat-select>
                      </mat-form-field>
                  
                  </div>
      
                </mat-card-content>
              </mat-card>
              <mat-card>
                <mat-card-content>
                    <div fxFlex fxLayout="column">
                        <mat-form-field hideRequiredMarker>
                            <input matInput (dateChange)="dateChange()" [matDatepicker]="picker1" placeholder="Start Date" name="startdate" required [(ngModel)]="startdate">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
            
                          </mat-form-field>
      
                          <mat-form-field hideRequiredMarker>
                              <input matInput [min]="startdate"  [matDatepicker]="picker2" placeholder="Finish Date" name="finishdate" required [(ngModel)]="finishdate">
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
              
                            </mat-form-field>
                            <mat-form-field hideRequiredMarker>
                                <input matInput type="time"   placeholder="From" name="start_time" required [(ngModel)]="start_time">
                            </mat-form-field>
                            <mat-form-field hideRequiredMarker>
                                <input matInput type="time"   placeholder="To" name="finish_time" required [(ngModel)]="finish_time">
                            </mat-form-field>
                    </div>
                    
                </mat-card-content>
              </mat-card>
              <mat-card>
                <mat-card-content>
                  <div fxFlex fxLayout="column">
                      <mat-checkbox name="regchek"  [(ngModel)]="regchek">Registar Required</mat-checkbox>
                      <mat-form-field *ngIf="regchek" hideRequiredMarker>
                          <input matInput  placeholder="Registar Link" name="registar_link" required  [(ngModel)]="eventModel.registar_link">
                        </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card>
                  <mat-card-content>
                    <div fxFlex fxLayout="column">
                        
                        <mat-form-field  hideRequiredMarker>
                            <input matInput  placeholder="Instructor" name="instractor"   [(ngModel)]="eventModel.instractor">
                          </mat-form-field>
                          <mat-form-field  hideRequiredMarker>
                              <input matInput  placeholder="Instructor Website" name="instractor_link"   [(ngModel)]="eventModel.instractor_link">
                            </mat-form-field>
                    </div>
                  </mat-card-content>
                </mat-card>

    
        </div>
      </div>
   
    </form>
  </section>
