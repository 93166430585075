import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-sidebar-list',
  templateUrl: './sidebar-list.component.html',
  styleUrls: ['./sidebar-list.component.css']
})
export class SidebarListComponent implements OnInit {

  @Output() sidebarColse = new EventEmitter<void>();
  navList: any[] = [];

  @Input() userName: string;
  constructor(private router: Router, private authService:AuthService) { }

  ngOnInit() {
    this.navList.push(
      { name: "Team", path: "/team", icon: "supervisor_account",query: {status: 'active'}},
      { name: "Portfolio", path: "/portfolio", icon: "photo_library",query: {status: 'active'} },
      { name: "Blog", path: "/blog", icon: "comment",query: {group:'design-build'} },
      { name: "Press Releases", path: "/press-releases", icon: "assignment",query: {status: 'active'}},
      { name: "Events", path: "/events", icon: "event",query: {status: 'active'} },
      { name: "Podcasts", path: "/podcasts", icon: "keyboard_voice",query: {deleted: false} },
 
    )
  }
  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url :  this.router.url.slice(0, queryParamsIndex);

    return baseUrl.startsWith(url);

 }
  onClose() {
    this.sidebarColse.emit()
  }

  onLogout() {
    this.authService.logout();
  }
}
