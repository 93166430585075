import { QuestionDialogComponent } from './../../customs/question-dialog/question-dialog.component';
import { TeamService } from './team.service';
import { Team } from './team.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UIService } from '../../ui.service';


@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['select', 'image', 'name', 'title', 'department', 'order'];
  dataSource = new MatTableDataSource<Team>();
  selection = new SelectionModel<Team>(true, []);
  status;
teams: Team[] =[];
  teamSubscription: Subscription;
  teamChangedSubscription: Subscription;
  hostUrl = environment.serverName;
  constructor(private teamService: TeamService, private activatedRoute: ActivatedRoute, private router: Router, private dialog:MatDialog, public uiService:UIService) { }

  ngOnInit() {
    // subscribe to router event
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.status = params['status'];
      if (!this.status)
        this.router.navigate(['/team'], { queryParams: { status: 'active' } });
      else
        this.teamService.fetchTeams(this.status);
    });

    this.teamSubscription = this.teamService.teamsChanged.subscribe(result => {
      this.teams = result;
      this.dataSource.data = this.teams;
    });

    this.teamChangedSubscription = this.teamService.teamChanged.subscribe(result=>{
      const index: number =this.teams.findIndex(x=> x.id === result.id);
      if (index !== -1) {
        this.teams.splice(index, 1);
        this.dataSource.data = this.teams;
      }
    });

  }

  onEdit(id) {
    this.router.navigate(['team/' + id])
  }

  changeStatus(team: Team) {
    
    const data =this.status == "active"? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.teamService.changeStatus(team);
      }
    });
   
  }
  ngOnDestroy() {
    if (this.teamSubscription)
    this.teamSubscription.unsubscribe();
    if (this.teamChangedSubscription)
    this.teamChangedSubscription.unsubscribe();
  }
}
