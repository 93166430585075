<div *ngIf="uiService.isLoading" class="disabled">
  <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
</div>
<section *ngIf="press" class="main-div" fxLayout="column" fxLayoutAlign="center center">

  <form #f="ngForm" (submit)="onSave()" autocomplete="nope">
    <mat-toolbar>
      <div fxFlex fxLayout>
        <h2>Edit Press Releas</h2>
      </div>
      <div fxFlex fxLayoutGap="5px" fxLayoutAlign="end">
        <button type="button" mat-raised-button (click)="onCancel()">Cancel</button>
        <button type="submit" mat-raised-button [disabled]="f.invalid" color="primary">Save</button>
      </div>
    </mat-toolbar>

    <div fxLayout="row" fxLayout.lt-lg="column" fxFlexFill fxLayoutGap="20px">
      <div fxFlex="100" fxLayout="column" fxLayoutGap="15px">


        <mat-form-field hideRequiredMarker fxLayout="column">
          <input matInput placeholder="Title" name="title" required autocomplete="nope" [(ngModel)]="press.title">
        </mat-form-field>

        <mat-form-field *ngIf="url_show" hideRequiredMarker fxFlex>
          <input matInput placeholder="Url" name="in_url_id" required [(ngModel)]="press.in_url_id">
        </mat-form-field>
        <div *ngIf="!url_show" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="15px"
          fxLayoutAlign.gt-sm="start center">
          <p *ngIf="!isActive">{{'https://www.armincoinc.com/press-releases/' +press.in_url_id}}</p>
          <a [href]="'https://www.armincoinc.com/press-releases/' +press.in_url_id" target="_blank"
            *ngIf="isActive">{{'https://www.armincoinc.com/press-releases/' +press.in_url_id}}</a>
          <button *ngIf="!isActive" type="button" mat-flat-button class="btn-small"
            (click)="url_show = true">Edit</button>
        </div>

        <mat-card class="card-textArea-short">
          <mat-card-content>
            <mat-form-field appearance="outline" hideRequiredMarker fxFlex>
              <mat-label>Short Description</mat-label>
              <textarea matInput name="short_dec" placeholder="Write a short description for the news here." required
                [(ngModel)]="press.short_dec"></textarea>
            </mat-form-field>
          </mat-card-content>
        </mat-card>

    
          <mat-card class="card-textArea" *ngIf="press?.mode == 'In'">
            <mat-card-content>
              <mat-form-field appearance="outline" hideRequiredMarker fxFlex>
                <mat-label>News Content</mat-label>
                <textarea matInput name="content" placeholder="Write the news content here." required
                  [(ngModel)]="press.content"></textarea>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="press?.mode == 'Out'">
            <mat-card-content>
              <mat-form-field  hideRequiredMarker fxFlex>
                <input matInput placeholder="Out Url" name="out_url_id" required [(ngModel)]="press.out_url_id">
              </mat-form-field>
            </mat-card-content>
            </mat-card>
          <app-image-upload [images]="press.images" (uploading)="onUploding($event)" [errorMessage]="wrongfiles"
            choseMode="false" (imageDelete)="imageDelete($event)"></app-image-upload>
       

     

      </div>


      <div fxFlex="300px" fxFlex.lt-lg="100" fxLayout="column" fxLayoutGap="15px">
        <mat-card>
          <mat-card-content>
            <div fxFlex fxLayout="column">
              <mat-form-field hideRequiredMarker>
                <mat-select placeholder="Status" name="status" [(ngModel)]="press.status" required
                  [disabled]="isActive">
                  <mat-option value="Draft">Draft</mat-option>
                  <mat-option value="Active">Active</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field hideRequiredMarker>
                <input matInput [matDatepicker]="picker" placeholder="Date Of News" name="date" required
                  [(ngModel)]="press.date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

              </mat-form-field>
              <mat-form-field hideRequiredMarker>
                <input matInput placeholder="Author Name" name="author_name" required autocomplete="nope"
                  [(ngModel)]="press.author_name">
              </mat-form-field>
            </div>

          </mat-card-content>
        </mat-card>
        <mat-card class="card-textArea-tags">
          <mat-card-content>
            <div fxFlex fxLayout="column">


              <mat-form-field appearance="outline" hideRequiredMarker fxFlex>
                <mat-label>Tags</mat-label>
                <textarea matInput name="tags" required [(ngModel)]="press.tags"></textarea>
              </mat-form-field>

            </div>

          </mat-card-content>
        </mat-card>

        <mat-card class="card-image">
          <mat-card-header fxLayout="row">
            <h4 fxFlex>Title Image</h4>
            <button type="button" fxLayout fxLayoutAlign="end center" mat-button color="primary"
              (click)="onChose()">Choose Image</button>
          </mat-card-header>
          <mat-card-content>
            <div fxFlex fxLayout="column" fxLayoutAlign="center center">
              <input style="display: none" type="text" name="title_image" required [(ngModel)]="press.title_image">
              <div class="image-empty" fxLayout="column" fxLayoutAlign="center center">
                <img *ngIf="!press.title_image" src="../../../../assets/images/gallery-512.png" empty>
                <app-image-item *ngIf="press.title_image" [src]="press.title_image" (delete)="press.title_image= ''">
                </app-image-item>

                <h4 *ngIf="!press.title_image">Chose an image from images</h4>
              </div>
            </div>

          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-content fxFlex fxLayout="column">
            <label>Mode</label>
            <mat-radio-group class="radio-group" name="mode" [(ngModel)]="press.mode">
              <mat-radio-button class="radio-button" value="In">In Website</mat-radio-button>
              <mat-radio-button class="radio-button" value="Out">Out of Website</mat-radio-button>

            </mat-radio-group>

          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</section>