
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SidebarMobileHeaderComponent } from './sidebar-mobile-header/sidebar-mobile-header.component';
import { SidebarListComponent } from './sidebar-list/sidebar-list.component';


@NgModule({
    declarations: [
        SidebarListComponent,
        SidebarMobileHeaderComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        FlexLayoutModule
    ],
  exports:[SidebarListComponent,SidebarMobileHeaderComponent]
})
export class SidebarModule { }