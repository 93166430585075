import { environment } from './../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-item',
  templateUrl: './image-item.component.html',
  styleUrls: ['./image-item.component.css']
})
export class ImageItemComponent implements OnInit {
  @Input('src') image_src = "";
  @Output() delete = new EventEmitter<string>();
  @Output() chosen = new EventEmitter<string>();

  @Input() choseMode = "false";

  hostUrl = environment.serverName;
  constructor() { }

  ngOnInit() {
  }
  onDelete() {
    this.delete.emit(this.image_src);
  }

  onCopyText() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.image_src;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  onChose() {
    this.chosen.emit(this.image_src);
  }
}
