<div class="main-div ">
  <div class="over" fxFlex fxLayout="row" fxLayoutAlign="center center">
      <button *ngIf="choseMode == 'false'" mat-icon-button  type="button" matTooltip="Delete file" (click)="onDelete()">
          <mat-icon>delete_forever</mat-icon>
        </button>
        <button *ngIf="choseMode == 'false'" mat-icon-button  type="button" matTooltip="Copy file patch" (click)="onCopyText()">
            <mat-icon>insert_comment</mat-icon>
          </button>
          <button *ngIf="choseMode == 'true'" mat-icon-button  type="button" [mat-dialog-close]="true" (click)="onChose()" style="height: 100%;width: 100%;">
              <mat-icon>check</mat-icon>
            </button>
         
  </div>
  <img class="mat-elevation-z3" [src]="hostUrl + image_src">

</div>