<mat-toolbar>
    <div fxFlex fxLayout>
      <h2>Podcasts</h2>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="end">
      <button mat-raised-button color="primary" (click)="addNew()">
        <mat-icon>add</mat-icon> NEW</button>
    </div>
  </mat-toolbar>

  <mat-card>
    <nav mat-tab-nav-bar>
      <a mat-tab-link routerLink="/podcasts" [queryParams]="{deleted: false}" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
       Active
      </a>
   
      <a mat-tab-link routerLink="/podcasts" [queryParams]="{deleted: true}" routerLinkActive #rla3="routerLinkActive" [active]="rla3.isActive">
       Deleted
      </a>
    </nav>
    <mat-card-content>
        <div *ngIf="uiService.isLoading"  class="wait">
          <mat-spinner ></mat-spinner>
      </div>
     
      <div class="empty" *ngIf="dataSource.data.length == 0  && !uiService.isLoading" fxLayout="column"  fxLayoutAlign="center center">
          <h2 >No Data</h2>
      </div>
         
          <table  *ngIf="dataSource.data.length > 0 && !uiService.isLoading" mat-table  [dataSource]="dataSource"
             matSort (matSortChange)="onSort()"  >
  
  
          <!-- Title Column -->
          <ng-container matColumnDef="podcast">
         
            <td mat-cell *matCellDef="let element" >
              <iframe [src]='element.src | safeUrl' frameborder='0' marginheight='0' marginwidth='0' scrolling='no' allowfullscreen></iframe>
            </td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="select">

            <td mat-cell *matCellDef="let row" style="padding-left: 24px;">
           
              <button mat-icon-button (click)="onEdit(row.id)">
                  <mat-icon>edit</mat-icon>
               
                </button>
  
                <button *ngIf="deleted == true"  mat-icon-button  (click)="changeStatus(row)">
                  <mat-icon>restore_from_trash</mat-icon>
                 
                </button>
  
                
                <button *ngIf="deleted == false" mat-icon-button (click)="changeStatus(row)">
                  <mat-icon>delete</mat-icon>
                 
                </button>
            
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
</mat-card>