<div *ngIf="uiService.isLoading" class="disabled">
  <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
</div>
<section *ngIf="team" fxLayout="column" fxLayoutAlign="center center">
  <form #f="ngForm" (submit)="onSave()">
    <mat-toolbar>
      <div fxFlex fxLayout>
        <h2>{{title}}</h2>
      </div>
      <div fxFlex fxLayoutGap="5px" fxLayoutAlign="end">
        <button type="button" mat-raised-button (click)="onCancel()">Cancel</button>
        <button type="submit" mat-raised-button [disabled]="f.invalid" color="primary">Save</button>
      </div>
    </mat-toolbar>
    <div fxLayout="row" fxLayout.lt-lg="column" fxFlexFill fxLayoutGap="20px">
      <div fxFlex="100" fxLayout="column" fxLayoutGap="15px">

        <mat-card>
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="15px">
              <div fxLayout="row">
                <mat-form-field hideRequiredMarker fxFlex="100">
                  <input matInput type="text" placeholder="Title" name="title" autocomplete="off"  [(ngModel)]="team.title">
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="15px">
                <mat-form-field hideRequiredMarker fxFlex="100">
                  <input matInput type="text" placeholder="Fisrt Name" name="fName" autocomplete="off"  [(ngModel)]="team.fName">
                </mat-form-field>
                <mat-form-field hideRequiredMarker fxFlex="100">
                  <input matInput type="text" placeholder="Last Name" name="lName" autocomplete="off"  [(ngModel)]="team.lName">
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="15px">
          <mat-card fxFlex="100"  class="card-image">
              <mat-card-header fxLayout="row">
                  <h4 fxFlex>Image 1</h4>
                  <button type="button" fxLayout fxLayoutAlign="end center" mat-button color="primary" (click)="onChose('image1')">Choose Image</button>
                </mat-card-header>
                <mat-card-content>
                  <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                    <input style="display: none" type="text" name="image1"  [(ngModel)]="team.image1">
                    <div class="image-empty" fxLayout="column" fxLayoutAlign="center center">
                      <img *ngIf="!team.image1" src="../../../../assets/images/gallery-512.png" empty>
                      <app-image-item *ngIf="team.image1" [src]="team.image1" (delete)="team.image1= ''"></app-image-item>
      
                      <h4 *ngIf="!team.image1">Chose an image from images</h4>
                    </div>
                  </div>
      
                </mat-card-content>
          </mat-card>
          <mat-card fxFlex="100" class="card-image">
              <mat-card-header fxLayout="row">
                  <h4 fxFlex>Image 2</h4>
                  <button type="button" fxLayout fxLayoutAlign="end center" mat-button color="primary" (click)="onChose('image2')">Choose Image</button>
                </mat-card-header>
                <mat-card-content>
                  <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                    <input style="display: none" type="text" name="image2" [(ngModel)]="team.image2">
                    <div class="image-empty" fxLayout="column" fxLayoutAlign="center center">
                      <img *ngIf="!team.image2" src="../../../../assets/images/gallery-512.png" empty>
                      <app-image-item *ngIf="team.image2" [src]="team.image2" (delete)="team.image2= ''"></app-image-item>
      
                      <h4 *ngIf="!team.image2">Chose an image from images</h4>
                    </div>
                  </div>
      
                </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div fxFlex="300px" fxFlex.lt-lg="100" fxLayout="column" fxLayoutGap="15px">
        <mat-card>
            <mat-card-content>
                <div fxFlex fxLayout="column" fxLayoutGap="15px">
                  <mat-form-field hideRequiredMarker >
                    <mat-select placeholder="Status" name="deleted" [(ngModel)]="team.deleted" required >
                      <mat-option [value]="false">Active</mat-option>
                      <mat-option [value]="true">Inactive</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field hideRequiredMarker>
                      <mat-select placeholder="Display Location" name="department" [(ngModel)]="team.department" required>
                        <mat-option value="About">About</mat-option>
                        <mat-option value="Architecture">Design & Architecture</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field hideRequiredMarker fxFlex="100">
                        <input matInput type="number" placeholder="Order Number" name="order" autocomplete="off" required [(ngModel)]="team.order">
                      </mat-form-field>
                </div>
    
              </mat-card-content>
        </mat-card>

      </div>
    </div>
  </form>

</section>