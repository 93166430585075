import { Router } from '@angular/router';
import { UIService } from './../../ui.service';
import { environment } from './../../../environments/environment';
import { HttpService } from './../../http.service';
import { Team } from './team.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class TeamService {


    imagesChanged = new Subject<string[]>();
    imagesUploaded = new Subject<any>();

    imageDeleted = new Subject<string>();
    teamsChanged = new Subject<Team[]>();
    teamChanged = new Subject<Team>();

    constructor(private httpService: HttpService, private uiService: UIService, private router:Router) { }

    fetchTeams(status: string) {
        this.uiService.isLoading = true;
        this.httpService.getTeams(status)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.teamsChanged.next([...result]);
            }, err => { this.uiService.handleError(err, true); });
    }

    fetchTeam(id: number) {
        this.uiService.isLoading = true;
        this.httpService.getTeam(id)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.teamChanged.next({...result});
            }, err => { this.uiService.handleError(err, true); });
    }

    addTeam(newData:Team) {
        this.uiService.isLoading = true;
        this.httpService.addTeam(newData)
            .subscribe(() => {
                this.uiService.isLoading = false;
                this.router.navigate(['team'], { queryParams: { status: newData.deleted == false? "active":"inactive" } });
            },err=>{ this.uiService.handleError(err);});
    }
    editTeam(data:Team) {
        this.uiService.isLoading = true;
        this.httpService.editTeam(data)
            .subscribe(() => {
                this.uiService.isLoading = false;
                this.router.navigate(['team'], { queryParams: { status:  data.deleted == false? "active":"inactive"} });
            },err=>{ this.uiService.handleError(err);});
    }

    changeStatus(data:Team){
        this.uiService.isLoading = true;
        let status =   data.deleted == false? "active":"inactive";
        data.deleted = !data.deleted;
        this.httpService.editTeam(data)
        .subscribe(() => {
            this.uiService.isLoading = false;
          this.teamChanged.next({...data});
        },err=>{ this.uiService.handleError(err);});
    }

    ftechTeamImages() {
        this.uiService.isLoading = true;
        this.httpService.getTeamImages()
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.imagesChanged.next([...result]);
            }, err => { this.uiService.handleError(err, true); });
    }
    upleadTeamPhoto(formData: FormData) {
        this.uiService.isLoading = true;
        this.httpService.uploadTeamImage(formData)
            .subscribe(result => {
                this.uiService.isLoading = false;
                this.imagesUploaded.next([...result]);
            }, err => { this.uiService.handleError(err); });
    }

    deleteTeamImage(path) {
        this.uiService.isLoading = true;
        this.httpService.deletedTeamImage(path).subscribe(result => {
            this.uiService.isLoading = false;
            this.imageDeleted.next(path);
        }, err => {
            this.uiService.handleError(err);
        });
    }
}