import { AddPortfolioComponent } from './add-portfolio/add-portfolio.component';
import { Portfolio } from './portfolio.model';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PortfolioService } from './portfolio.service';
import { QuestionDialogComponent } from '../../customs/question-dialog/question-dialog.component';
import { UIService } from '../../ui.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})


export class PortfolioComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'image' , 'title', 'group', 'order'];
  dataSource = new MatTableDataSource<Portfolio>();
  selection = new SelectionModel<Portfolio>(true, []);
 

  portfoliosSubscription: Subscription;

  status;

  constructor(private portfolioService: PortfolioService, private activatedRoute: ActivatedRoute,private dialog: MatDialog, private router: Router,public uiService:UIService) { }

  ngOnInit() {
     // subscribe to router event
     this.activatedRoute.queryParams.subscribe((params: Params) => {
     this.status = params['status'];
      this.portfolioService.fetchPortfolios(this.status);
    });
    
   this.portfoliosSubscription = this.portfolioService.portfoliosChanged.subscribe(result=>{
     
     this.dataSource.data = result;
     this.dataSource.sort = this.sort;
   });
  
  }


  addPortfolio() {
    const portfolio ={title:"", city :"",state:"",client:""}
    const dialogRef = this.dialog.open(AddPortfolioComponent, { width: "800px", panelClass: 'dialog-top', data: portfolio });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
         this.portfolioService.addPortfolio(portfolio);
      }
    });
  }
  onEdit(id) {
    this.router.navigate(['/portfolio/' + id]);
  }

  changeStatus(portfolio: Portfolio) {
    
    const data =this.status == "active"? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";
    const dialogRef = this.dialog.open(QuestionDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.portfolioService.changeActivation(portfolio.id);
      }
    });
   
  }

  onSort(){
    this.dataSource.sort = this.sort;
  
  }
  ngOnDestroy(){
    this.portfoliosSubscription.unsubscribe();
  }

}
