import { Router } from "@angular/router";
import { environment } from "./../../../environments/environment";
import { BehaviorSubject, Subject } from "rxjs";
import { News } from "./news.model";
import { HttpService } from "./../../http.service";
import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { UIService } from "../../ui.service";

@Injectable()
export class NewsService {
  newses: News[] = [];
  newsesChanged = new Subject<News[]>();
  newsChanged = new Subject<News>();
  imagesChanged = new Subject<any>();
  imageDeleted = new Subject<any>();
  readonly uploadedNews = new Subject<News>();
  readonly importedNews = new BehaviorSubject<News>(null);
  news: News;
  constructor(
    private httpService: HttpService,
    private router: Router,
    private uiService: UIService
  ) {}
  fetchNewses(group) {
    this.uiService.isLoading = true;
    this.httpService.getNewses(group).subscribe(
      (result) => {
        this.newses = result;
        this.newses.forEach((x) => {
          if (x.title_image != null && x.title_image != "") {
            x.title_image = environment.serverName + x.title_image;
          } else {
            x.title_image = "assets/images/no-preview-wide.jpg";
          }
        });
        this.uiService.isLoading = false;
        this.newsesChanged.next([...this.newses]);
      },
      (err) => {
        this.uiService.handleError(err, true);
      }
    );
  }
  fetchNews(id) {
    this.uiService.isLoading = true;
    this.httpService.getNews(id).subscribe(
      (result) => {
        this.news = result;
        this.uiService.isLoading = false;
        this.newsChanged.next({ ...this.news });
      },
      (err) => {
        this.uiService.handleError(err, true);
      }
    );
  }

  onUplodingNews(fileList: FileList) {
    let file = fileList[0];
    let fileReader: FileReader = new FileReader();
    let self = this;
    fileReader.onloadend = function (x) {
      let text = fileReader.result as string;
      let newNews: Partial<News> = {};

      const titleStartTag = "<h1>";
      const titleEndTag = "</h1>";
      const short_decStartTag = "<h6>";
      const short_decEndTag = "</h6>";
      const tagsStartTag = "<h4>Keyword(s):";
      const tagsEndTag = "</h4>";
      const contentStartTag = "<p>";
      const contentEndTag = "</p>";

      let titleStartIndex = text.indexOf(titleStartTag);
      let titleEndIndex = text.indexOf(titleEndTag);

      let short_decStartIndex = text.indexOf(short_decStartTag);
      let short_decEndIndex = text.indexOf(short_decEndTag);

      let tagsStartIndex = text.indexOf(tagsStartTag);
      let tagsEndIndex = text.indexOf(tagsEndTag);

      let contentStartIndex = text.indexOf(contentStartTag);
      let contentEndIndex = text.lastIndexOf(contentEndTag);

      if (
        titleStartIndex == -1 ||
        short_decStartIndex == -1 ||
        tagsStartIndex == -1 ||
        contentStartIndex == -1
      ) {
        self.uiService.snackBar.open("File is not valid", null, {
          duration: 3000,
          verticalPosition: "top",
          panelClass: "snakbar-warn",
        });
        return null;
      }

      newNews.title = text
        .substring(titleStartIndex, titleEndIndex)
        .replace(titleStartTag, "")
        .trim();
      newNews.short_dec = text
        .substring(short_decStartIndex, short_decEndIndex)
        .replace(short_decStartTag, "")
        .trim();
      newNews.tags = text
        .substring(tagsStartIndex, tagsEndIndex)
        .replace(tagsStartTag, "")
        .trim();
      newNews.content = text
        .substring(contentStartIndex, contentEndIndex)
        .replace(contentStartTag, "")
        .trim();
      newNews.group = "design-build";
      self.uploadedNews.next(newNews as News);
    };
    fileReader.readAsText(file);
  }

  importNews(newData) {
    this.uiService.isLoading = true;
    this.httpService.addNews(newData).subscribe(
      (result) => {
        this.uiService.isLoading = false;
        this.importedNews.next(newData);
        this.router.navigate(["blog/" + result.id]);
      },
      (err) => {
        this.uiService.handleError(err);
      }
    );
  }

  addNews(newData) {
    this.uiService.isLoading = true;
    this.httpService.addNews(newData).subscribe(
      (result) => {
        this.uiService.isLoading = false;
        this.router.navigate(["blog/" + result.id]);
      },
      (err) => {
        this.uiService.handleError(err);
      }
    );
  }
  editNews(data) {
    this.uiService.isLoading = true;
    this.httpService.editNews(data).subscribe(
      () => {
        this.uiService.isLoading = false;
        this.cleanImport();
        this.router.navigate(["blog"], { queryParams: { group: data.group } });
      },
      (err) => {
        this.uiService.handleError(err);
      }
    );
  }

  deleteNews(id) {
    this.uiService.isLoading = true;
    this.httpService.deletedNews(id).subscribe(
      () => {
        const index = this.newses.findIndex((x) => x.id == id);
        this.newses.splice(index, 1);
        this.uiService.isLoading = false;
        this.newsesChanged.next([...this.newses]);
      },
      (err) => {
        this.uiService.handleError(err);
      }
    );
  }

  upleadNewsPhoto(id, formData: FormData) {
    this.uiService.isLoading = true;
    this.httpService.uploadNewsImage(id, formData).subscribe(
      (result) => {
        this.uiService.isLoading = false;
        this.imagesChanged.next(result);
      },
      (err) => {
        this.uiService.handleError(err);
      }
    );
  }

  deletedNewsImage(id, path) {
    this.uiService.isLoading = true;
    this.httpService.deletedNewsImage(id, path).subscribe(
      (result) => {
        this.uiService.isLoading = false;
        this.imageDeleted.next(path);
      },
      (err) => {
        this.uiService.handleError(err);
      }
    );
  }

  cleanImport() {
    this.importedNews.next(null);
  }
}
