<mat-toolbar>
  <div fxFlex fxLayout>
    <h2>Team</h2>
  </div>
  <div fxFlex fxLayout fxLayoutGap="10px" fxLayoutAlign="end">
      <a  mat-raised-button routerLink="images">
          <mat-icon>photo_library</mat-icon> Images</a >
    <a  mat-raised-button color="primary" routerLink="new">
      <mat-icon>add</mat-icon> NEW</a >
   
  </div>

</mat-toolbar>

  <mat-card>

    <nav mat-tab-nav-bar>
      <a mat-tab-link routerLink="/team" [queryParams]="{status:'active'}" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
        Active
      </a>
      <a mat-tab-link routerLink="/team" [queryParams]="{status:'inactive'}" routerLinkActive #rla2="routerLinkActive" [active]="rla2.isActive">
        Inactive
      </a>
    </nav>

    <mat-card-content>
      <div *ngIf="uiService.isLoading"  class="wait">
        <mat-spinner ></mat-spinner>
    </div>
   
    <div class="empty" *ngIf="dataSource.data.length == 0  && !uiService.isLoading" fxLayout="column"  fxLayoutAlign="center center">
        <h2 >No Data</h2>
    </div>
       
        <table  *ngIf="dataSource.data.length > 0 && !uiService.isLoading" mat-table [dataSource]="dataSource">

        <!-- Action Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">

              <button mat-menu-item  (click)="onEdit(row.id)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button *ngIf="status == 'inactive'" mat-menu-item (click)="changeStatus(row)">
                <mat-icon>check_circle_outline</mat-icon>
                <span>Active</span>
              </button>

              
              <button *ngIf="status == 'active'" mat-menu-item (click)="changeStatus(row)">
                <mat-icon>block</mat-icon>
                <span>Inactive</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef>Image</th>
          <td mat-cell *matCellDef="let element">
            <img class="image-cell mat-elevation-z2" [src]="element.image1 == null || element.image1 ==''? 'assets/images/no-preview-wide.jpg' : hostUrl + element.image1">
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element"> {{element.fName + ' ' + element.lName}} </td>
        </ng-container>


        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef fxHide.lt-sm="true"> Title </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-sm="true"> {{element.title}} </td>
        </ng-container>

        <!-- Department Column -->
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef fxHide.lt-md="true"> Department </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-md="true"> {{element.department}} </td>
        </ng-container>

        <!-- Order Column -->
        <ng-container matColumnDef="order">
          <th mat-header-cell *matHeaderCellDef fxHide.lt-lg="true"> Order </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg="true"> {{element.order}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" fxHide.lt-sm="true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
