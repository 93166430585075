import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { ImageItemComponent } from "./image-item/image-item.component";
import { SafeUrlPipe } from "./safe-url.pipe";
import { ImageUploadComponent } from "./image-upload/image-upload.component";
import { ChoseFileDialogComponent } from "./chose-file-dialog/chose-file-dialog.component";
import { QuestionDialogComponent } from "./question-dialog/question-dialog.component";
import { MaterialModule } from '../material.module';
import { MonthNamePipe } from './month_name.pipe';

@NgModule({
    declarations: [
        ImageItemComponent,
        SafeUrlPipe,
        MonthNamePipe,
        ImageUploadComponent,
        ChoseFileDialogComponent,
        QuestionDialogComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule
    ],
    exports:[ImageItemComponent,ImageUploadComponent,SafeUrlPipe,MonthNamePipe],
    entryComponents: [ChoseFileDialogComponent, QuestionDialogComponent]
})
export class CustomsModule { } 